import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Flex,
  Div,
  Container,
  Banner,
  ContentContainer,
  Typo,
  Grid,
  Collapsible,
  CollapsibleContent,
  ShopItem,
  CollapsibleTrigger,
  LoadingIcon,
} from "../components";
import { SquareInput } from "../components/SquareInputs";
import themes from "../themes";
import Context from "../Context";
import styled from "styled-components";
import { validate, getThaiDateFormat, devices } from "../constants";

import { RiSafeFill } from "react-icons/ri";

import Carousel from "react-multi-carousel";
import {
  FcDonate,
  FcShop,
  FcCurrencyExchange,
  FcTemplate,
} from "react-icons/fc";
import { GiCash } from "react-icons/gi";

import { FaArrowRight } from "react-icons/fa";

import { useToast } from "../components/ui";
import { PiSealCheckFill } from "react-icons/pi";

export const LandingPage = () => {
  const { toast } = useToast();
  const { go, configs, callApi, profileState } = Context();
  const [roundDate, setRoundDate] = useState(
    configs.isAwardReady
      ? configs.roundDate
      : configs.roundDates

          .filter(
            (roundDate) =>
              roundDate !== configs.roundDate &&
              roundDate !== configs.nextRoundDate
          )
          .sort()
          .reverse()[0]
  );
  const [showAllShortcut, setShowAllShortcut] = useState(false);
  const [prizes, setPrizes] = useState({});
  const [blocksList, setBlocksList] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRoundPrize();
    getLotto();
  }, [roundDate]);

  const getRoundPrize = () => {
    return callApi("prizes-getRoundPrizes", { roundDate }).then((result) =>
      setPrizes(result)
    );
  };

  const getLotto = async () => {
    const blocks = await callApi("blocks-searchBlocks", {
      random: Math.floor(Math.random() * 1000000),
      isPrettyFront: false,
      isSpecialNumber: false,
      isMagicNumber: false,
      isLabubuNumber: false,
      limit: 4,
    });
    setBlocksList(blocks);
  };

  const shortcutPanel = [
    {
      name: "คูปอง",
      onClick: () => go("/coupon"),
      icon: "/images/icon-coupon.svg",
    },
    {
      name: "รายได้ชวนเพื่อน",
      onClick: () => go("/affilate"),
      icon: "/images/income.png",
      disable: !configs.isOpen.affilate,
    },
    {
      name: "ส่งของขวัญ",
      onClick: () => {
        sessionStorage.setItem("sendAsAGift", true);
        toast({
          className: "bg-[rgb(59,176,101)]",
          title: `ซื้อแบบส่งของขวัญ`,
        });
        return go("/search/");
      },
      icon: "/images/icon-ส่งของขวัญ.svg",
    },
    {
      name: "แลกคะแนน",
      onClick: () => go("/point/products"),
      icon: "/images/icon-แลกคะแนน.svg",
    },
    {
      name: "สินค้าพรีเมียม",
      onClick: () => go("/shop/products"),
      icon: "/images/icon-สินค้าพรีเมียม.svg",
    },
    {
      name: "ติดต่อเรา",
      onClick: () =>
        window.open("https://openlink.co/hongthongdotcom", "_blank"),
      icon: "/images/operator.png",
    },
    {
      name: "ภารกิจ",
      onClick: () => (configs.isOpen.mission ? go("/mission") : undefined),
      icon: "/images/mission.svg",
      disable: !configs.isOpen.mission,
    },

    {
      name: "ปันน้ำใจ",
      onClick: () => (configs.isOpen.donate ? go("/donate") : undefined),
      icon: "/images/icon-บริจาค.svg",
      disable: !configs.isOpen.donate,
    },

    {
      name: "ดูดวง",
      onClick: () =>
        window.open("https://www.hongthongofficial.com/fortune", "_blank"),
      icon: "/images/cards.svg",
    },
    {
      name: "ข่าวสาร",
      onClick: () => go("https://www.hongthongofficial.com/news/list/1"),
      icon: "/images/icon-ข่าวสาร.svg",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide = 0 },
    } = rest;
    return (
      <div className="carousel-button-group flex gap-2 justify-center items-center">
        <button
          className={`w-4 h-1.5 duration-150 rounded-lg ${
            currentSlide === 0 ? "bg-navy w-6" : "bg-slate-500 opacity-55"
          }`}
          onClick={() => goToSlide(0)}
        ></button>
        <button
          className={`w-4 h-1.5 duration-150 rounded-lg ${
            currentSlide === 1 ? "bg-navy w-6" : "bg-slate-500 opacity-55"
          }`}
          onClick={() => goToSlide(1)}
        ></button>
      </div>
    );
  };

  return (
    <Container margin="0 0 86px 0" className="">
      <Banner />

      <ContentContainer minHeight="0" padding="0">
        <div>
          <Carousel
            className="pt-4 sm:pt-12 pb-4"
            arrows={false}
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={false}
            autoPlay={false}
            keyBoardControl={false}
            containerClass="carousel-container"
            // renderButtonGroupOutside={true}
            // customButtonGroup={<ButtonGroup />}
            itemClass="carousel-item-padding-40-px"
          >
            <div className="grid grid-cols-5 px-2 gap-3">
              {shortcutPanel.slice(0, 10).map((shortcut) => {
                return (
                  <div
                    className={`flex w-fit h-fit mx-auto flex-col justify-center items-center gap-1  ${
                      shortcut.disable
                        ? "cursor-not-allowed grayscale"
                        : "cursor-pointer hover:scale-125 duration-75"
                    }`}
                    onClick={!shortcut.disable ? shortcut.onClick : undefined}
                    key={shortcut.name}
                  >
                    <img
                      className="w-10 h-10 sm:w-12 sm:h-12"
                      src={shortcut.icon}
                      alt="coupon"
                    />
                    <p className="text-xxs md:text-base text-center font-medium pt-2">
                      {shortcut.name}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* <div className="grid grid-cols-4 px-2 gap-3">
              {shortcutPanel.slice(10, 16).map((shortcut) => {
                return (
                  <div
                    className={`flex flex-col justify-center items-center gap-1  ${
                      shortcut.disable
                        ? "cursor-not-allowed grayscale"
                        : "cursor-pointer hover:scale-125 duration-75"
                    }`}
                    onClick={!shortcut.disable ? shortcut.onClick : undefined}
                    key={shortcut.name}
                  >
                    <img
                      src={shortcut.icon}
                      alt="coupon"
                      style={{ height: "55px" }}
                    />
                    <p className="text-xs md:text-base text-center font-medium pt-2">
                      {shortcut.name}
                    </p>
                  </div>
                );
              })}
            </div> */}
          </Carousel>
        </div>
        {/* <div className="grid grid-cols-4 gap-3">
          {shortcutPanel.map((shortcut) => {
            return (
              <div
                className={`flex flex-col justify-center items-center gap-1  ${
                  shortcut.disable
                    ? "cursor-not-allowed grayscale"
                    : "cursor-pointer hover:scale-125 duration-75"
                }`}
                onClick={!shortcut.disable ? shortcut.onClick : undefined}
                key={shortcut.name}
              >
                <img
                  src={shortcut.icon}
                  alt="coupon"
                  style={{ height: "55px" }}
                />
                <p className="">{shortcut.name}</p>
              </div>
            );
          })}
          {!showAllShortcut && (
            <div
              className={`flex flex-col justify-center items-center gap-1 cursor-pointer hover:scale-125 duration-75`}
              onClick={() => setShowAllShortcut(true)}
            >
              <FaArrowRight className="h-[55px] text-3xl text-yellow-400" />
              <ShortCutText textAlign="center" color="textBlack">
                ดูทั้งหมด
              </ShortCutText>
            </div>
          )}
        </div> */}
      </ContentContainer>

      {configs.isOpen.store && blocksList.length > 0 ? (
        <>
          <LottoShop blocksList={blocksList} />
        </>
      ) : (
        <>
          <Button
            className="my-3"
            background="gradient2"
            gap="10px"
            width="100%"
            onClick={() => go("/search")}
          >
            อยากเป็นเจ้าของรางวัลที่ 1{" "}
            <span style={{ color: themes.colors.yellow }}>คลิก!</span>
          </Button>
          <ContentContainer minHeight="0" padding="24px">
            <div className="flex justify-between">
              <Typo.H5 color="textBlack">
                ผลรางวัลงวด {getThaiDateFormat(new Date(roundDate))}
              </Typo.H5>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3  my-4 gap-3 align-bottom">
              <Div>
                <Typo.Body1 color="gray5">รางวัลที่ 1</Typo.Body1>
                <Typo.H2 color="navy">{prizes?.first?.numbers || "-"}</Typo.H2>
              </Div>

              <Div className="md:order-3">
                <Typo.Body1 color="gray5">เลขท้าย 2 ตัว</Typo.Body1>
                <Typo.H2 color="navy">
                  {prizes?.lastTwo?.numbers[0] || "-"}
                </Typo.H2>
              </Div>
              <Div>
                <Typo.Body1 color="gray5">เลขหน้า 3 ตัว</Typo.Body1>
                <Typo.H2 color="navy">
                  {prizes?.firstThree?.numbers[0] || "-"}{" "}
                  {prizes?.firstThree?.numbers[1] || "-"}
                </Typo.H2>
              </Div>
              <Div>
                <Typo.Body1 color="gray5">เลขท้าย 3 ตัว</Typo.Body1>
                <Typo.H2 color="navy">
                  {prizes?.lastThree?.numbers[0] || "-"}{" "}
                  {prizes?.lastThree?.numbers[1] || "-"}
                </Typo.H2>
              </Div>
            </div>
            <Button
              width="100%"
              size="1rem"
              onClick={() => go("/check-reward")}
              background="newGrReverse"
            >
              ตรวจผลรางวัล
            </Button>
          </ContentContainer>
        </>
      )}

      <div className="mt-8">
        <p className="text-center text-slate-700 text-2xl font-bold mb-4">
          ซื้อลอตเตอรี่กับหงษ์ทอง ดีอย่างไร?
        </p>
        <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4">
          <div className="flex flex-col p-3 shadow-md bg-white hover:shadow-xl hover:scale-105 rounded-md  duration-300">
            <PiSealCheckFill className="mx-auto h-28 text-[100px] mb-3 text-success" />
            <p className="text-lg font-semibold ">ตรวจลอตเตอรี่</p>
            <p className="text-sm ">
              รวดเร็ว ฉับไว รับประกันความ แม่นยำ ด้วยระบบการตรวจสอบ
              เลขรางวัลที่ถูกต้องและตรงกับ สำนักงานสลากกินแบ่งรัฐบาล
            </p>
          </div>
          <div className="flex flex-col p-3 shadow-md bg-white hover:shadow-xl hover:scale-105 rounded-md  duration-300">
            <RiSafeFill className="mx-auto h-28 text-[100px] mb-3 text-gold" />
            <p className="text-lg font-semibold"> จัดเก็บปลอดภัย</p>
            <p className="text-sm ">
              ลอตเตอรี่ทุกใบจะถูกจัดเก็บด้วย การสแกนเข้าระบบที่พัฒนาอย่าง
              ต่อเนื่องและมีประสิทธิภาพ จึง ง่ายต่อการค้นหาเลขที่ต้องการ
            </p>
          </div>
          <div className="flex flex-col p-3 shadow-md bg-white hover:shadow-xl hover:scale-105 rounded-md  duration-300">
            <p className="text-[3em] h-28 flex items-center justify-center font-bold text-center mb-3">
              x3
            </p>
            <p className="text-lg font-semibold">สูญหายจ่าย 3 เท่า!!</p>
            <p className="text-sm ">
              สถานที่จัดเก็บลอตเตอรี่ที่มีมาตรฐานความปลอดภัยขั้นสูงตลอด 24
              ชั่วโมง พร้อมรับประกันจ่าย เงิน 3 เท่าของรางวัลที่ถูก หาก
              ลอตเตอรี่เกิดการสูญหาย
            </p>
          </div>
          <div className="flex flex-col p-3 shadow-md bg-white hover:shadow-xl hover:scale-105 rounded-md  duration-300">
            <p className="text-[3em] h-28 font-bold flex items-center justify-center  text-center mb-3">
              FREE
            </p>
            <p className="text-lg font-semibold"> ขึ้นเงินรางวัลฟรี!!</p>
            <p className="text-sm ">
              หงษ์ทองรับขึ้นเงินรางวัลให้ฟรี! รอรับเงินโอนเข้าบัญชีภายใน 24
              ชั่วโมง ส่วนท่านที่ไม่ได้ซื้อกับเรา สามารถนำมาขึ้นเงินได้ เช่นกัน
              โดยมีค่าธรรมเนียมเพียง 1%
            </p>
          </div>
        </div>
      </div>
      {/* <Typo.H5 color="textBlack" margin="40px 0">
        ซื้อลอตเตอรี่กับหงษ์ทอง ดีอย่างไร
      </Typo.H5>
      <Grid
        columns={4}
        columnsTablet={2}
        columnsIpad={2}
        columnsMobile={1}
        align="baseline"
        alignTablet="baseline"
        alignIpad="baseline"
        justifyMobile="flex-start"
        justify="center"
        gap="10px"
        margin="25px 0"
      >
        <div className="flex flex-col items-center">
          <img src="/images/landing1.svg" alt="landing1" className="w-18" />
          <Typo.H7
            className="text-center"
            color="textBlack"
            margin="16px 0 8px 0"
          >
            ตรวจลอตเตอรี่
          </Typo.H7>
          <Typo.Body3 className="text-center" color="gray5">
            รวดเร็ว ฉับไว รับประกันความ แม่นยำ ด้วยระบบการตรวจสอบ
            เลขรางวัลที่ถูกต้องและตรงกับ สำนักงานสลากกินแบ่งรัฐบาล
          </Typo.Body3>
        </div>
        <div className="flex flex-col items-center">
          <img src="/images/landing2.svg" alt="landing2" className="w-18" />
          <Typo.H7
            className="text-center"
            color="textBlack"
            margin="16px 0 8px 0"
          >
            จัดเก็บปลอดภัย
          </Typo.H7>
          <Typo.Body3 className="text-center" color="gray5">
            ลอตเตอรี่ทุกใบจะถูกจัดเก็บด้วย การสแกนเข้าระบบที่พัฒนาอย่าง
            ต่อเนื่องและมีประสิทธิภาพ จึง ง่ายต่อการค้นหาเลขที่ต้องการ
          </Typo.Body3>
        </div>
        <div className="flex flex-col items-center">
          <img src="/images/landing3.svg" alt="landing3" className="w-18" />
          <Typo.H7
            className="text-center"
            color="textBlack"
            margin="16px 0 8px 0"
          >
            ขึ้นเงินรางวัลฟรี!!
          </Typo.H7>
          <Typo.Body3 className="text-center" color="gray5">
            หงษ์ทองรับขึ้นเงินรางวัลให้ฟรี! รอรับเงินโอนเข้าบัญชีภายใน 24
            ชั่วโมง ส่วนท่านที่ไม่ได้ซื้อกับเรา สามารถนำมาขึ้นเงินได้ เช่นกัน
            โดยมีค่าธรรมเนียมเพียง 1%
          </Typo.Body3>
        </div>
        <div className="flex flex-col items-center">
          <img src="/images/landing4.svg" alt="landing4" className="w-18" />
          <Typo.H7
            className="text-center"
            color="textBlack"
            margin="16px 0 8px 0"
          >
            สูญหายจ่าย 3 เท่า!!
          </Typo.H7>
          <Typo.Body3 className="text-center" color="gray5">
            สถานที่จัดเก็บลอตเตอรี่ที่มีมาตรฐานความปลอดภัยขั้นสูงตลอด 24 ชั่วโมง
            พร้อมรับประกันจ่าย เงิน 3 เท่าของรางวัลที่ถูก หาก
            ลอตเตอรี่เกิดการสูญหาย
          </Typo.Body3>
        </div>
      </Grid> */}
    </Container>
  );
};

const LottoShop = ({ blocksList }) => {
  const { callApi, go, profileState, configs } = Context();
  const [blocks, setBlocks] = useState();
  const [digits, setDigits] = useState(
    "xxxxxx".split("").map((x) => (x !== "x" ? x : ""))
  );
  const [, setTs] = useState(Date.now());

  useEffect(() => {
    setBlocks(blocksList);
  }, [blocksList]);
  // const getLotto = async () => {
  //   const blocks = await callApi("blocks-searchBlocks", {
  //     random: Math.floor(Math.random() * 1000000),
  //     isPrettyFront: false,
  //     isSpecialNumber: false,
  //     isMagicNumber: false,
  //     isLabubuNumber: false,
  //     limit: 4,
  //   });
  //   setBlocks(blocks);
  // };

  if (!blocks) {
    return (
      <div className="bg-white rounded-xl shadow-lg">
        <LoadingIcon />
      </div>
    );
  }

  const handleSearch = () => {
    console.log("gifits", digits);

    go(`/search/all/${digits.join("")}`);
  };

  return (
    <div className="bg-white rounded-md shadow-md my-2.5">
      {/* <p className="text-center font-semibold text-xl mt-4 ">
        งวดวันที่ {getThaiDateFormat(new Date(configs.roundDate))}
      </p> */}
      <div className="pt-6">
        <div className="max-w-[440px] mx-auto w-full flex gap-1 justify-center">
          <SquareInput
            className={`border-3 ${
              digits[0] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"1"}
            index={0}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`border-3 ${
              digits[1] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"2"}
            index={1}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`border-3 ${
              digits[2] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"3"}
            index={2}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`border-3 ${
              digits[3] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"4"}
            index={3}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`border-3 ${
              digits[4] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"5"}
            index={4}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`border-3 ${
              digits[5] !== "" ? "border-navy " : ""
            } w-12 h-12 sm:w-16 sm:h-16 duration-150 rounded-md text-2xl text-center text-navy focus:border-navy placeholder:text-gray-200 font-medium outline-none`}
            text={"6"}
            index={5}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
        </div>
        <div className="flex justify-center gap-2 mt-3">
          <button
            onClick={() => go(`/search/all/${digits.join("")}`)}
            className="bg-navy hover:opacity-80 text-white rounded-md py-2 px-3 font-semibold"
          >
            ค้นหางวด {getThaiDateFormat(new Date(configs.roundDate))}
          </button>
          <button
            className="  bg-[#FFCD00] rounded-md py-2 px-3 font-semibold hover:opacity-85"
            onClick={() => go("/search")}
          >
            ดูเพิ่มเติม
          </button>
        </div>
      </div>
      <div className="grid p-4 pt-0 grid-cols-2 my-4 md:grid-cols-4 gap-3">
        {blocks.map((block) => (
          <ShopItem key={block.number} {...block} />
        ))}
      </div>
    </div>
  );
};

const ShortCutText = styled.p`
  font-size: 1.25em;
  text-align: center;
  //ipad size
  @media only screen and (${devices.tablet}) {
    //width 648px + padding 20-20
    font-size: 0.8em;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    //width 335px + padding 20-20
    font-size: 0.7em;
  }
`;
