import React, { useRef } from "react";
import styled from "styled-components";
import themes from "../themes";
import { devices } from "../constants";

export const SquareInput = ({
  text,
  digits,
  setDigits,
  index,
  setTs,
  action,
  className,
}) => {
  const blockRef = useRef(null);
  const inputRef = useRef(null);
  const isDigit = (inp) => /^\d{1,2}$/.test(inp) && inp != "e";

  const handleInput = (e) => {
    setTs(Date.now());
    const change = e.key;
    if (change === "Enter") {
      return action(digits);
    }
    if (!digits[index] && change === "Backspace" && index > 0) {
      return blockRef.current.previousSibling.childNodes[0].focus();
    } else if (change === "Backspace" && digits[index]) {
      setDigits((old) => {
        old[index] = "";
        return old;
      });
    }
  };
  const handleChange = (e) => {
    const change = e.target.value;
    e.preventDefault();
    if (isDigit(change)) {
      setDigits((old) => {
        if (old[index]) {
          if (change[0] === old[index]) {
            old[index] = change[1];
          } else {
            old[index] = change[0];
          }
        } else {
          old[index] = change;
        }
        if (index === 5) {
          action();
        }
        return old;
      });
      if (index < digits.length - 1) {
        return blockRef.current.nextSibling.childNodes[0].focus();
      }
    } else if (change === "") {
      setDigits((old) => {
        old[index] = " ";
        return old;
      });
    }
  };

  return (
    <div ref={blockRef}>
      <input
        className={className}
        inputMode="numeric"
        type="tel"
        blockref={blockRef}
        ref={inputRef}
        onChange={() => {}}
        value={digits[index]}
        placeholder={text}
        onKeyUp={handleInput}
        onChangeCapture={handleChange}
      />
    </div>
  );
};
const Block = styled.div``;
// const Input = styled.input`
//   border: 2px solid ${(props) => themes.colors.gray};
//   border-radius: 8px;

//   :focus {
//     border: 3px solid ${themes.colors.yellow};
//   }
// `;
