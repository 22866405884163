import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const Banner = () => {
  const [currentImage, setCurrentImage] = useState(0);
  useEffect(() => {}, []);
  return (
    <Main
      className="mt-2"
      onClick={() =>
        window.open("https://www.facebook.com/Hongthonglotterysocial", "_blank")
      }
    >
      {/* <Image src={`/images/banner.jpg`} /> */}
      <Img src={`/images/banner.png`} alt="banner" />
    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  display: block;
  background-color: rgb(0, 0, 0, 0);

  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  @media only screen and (max-width: 543px) {
    max-width: 100%;
  }
`;
const Img = styled.img`
  width: 100%;

  background-position: center;
  background-image: url("${(props) => props.src}");
  background-size: contain;
`;
