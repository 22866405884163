import React, { useEffect, useState, useContext } from "react";
import Context from "../Context";
import {
  StackImages,
  Div,
  Flex,
  Button,
  Typo,
  Container,
  ContentContainer,
  OrderContainer,
  Switch,
  LoadingIcon,
  ScrollArea,
} from "../components";

import { roundDateToDate, getKeyTranslated } from "../util";
import { locale } from "../constants";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbLayoutList } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { Spinner, Collapse } from "reactstrap";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import celebrate1 from "../constants/lottie/celebrate-1.json";
import celebrate2 from "../constants/lottie/celebrate-2.json";

export const Orders = () => {
  const { callApi, configs, profileState, go } = Context();
  const [orderList, setOrderList] = useState(false);

  const [type, setType] = useState("order");
  const { param } = useParams();

  const getOrder = async () => {
    const { orderData } = await callApi(
      "orders-getUserOrders",
      {},
      { notShowLoading: true }
    );
    setOrderList(orderData || []);
  };

  useEffect(() => {
    (async () => {
      if (localStorage.accessToken) {
        window.scrollTo(0, 0);
        getOrder();

        if (param === "lotto") {
          setType("lotto");
        } else if (param === "history") {
          setType("history");
        }
      } else {
        go("/logout");
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className="m-0 ">
      <OrderViewSelector orderList={orderList} type={type} setType={setType} />
      {type === "order" ? (
        <AllOrder orderList={orderList} go={go} />
      ) : type === "lotto" ? (
        <AllLotto orderList={orderList} />
      ) : (
        <HistoryOrder orderList={orderList} />
      )}
    </Container>
  );
};

const OrderViewSelector = ({ type, setType }) => {
  const { go, profileState } = Context();
  return (
    <div className="grid grid-cols-3  bg-white my-3 p-1 shadow-md rounded-lg">
      <button
        className={`text-navy py-2 text-xs sm:text-base font-semibold ${
          type === "order" && "bg-gold"
        } rounded-[12px]`}
        onClick={() => setType("order")}
      >
        ออเดอร์ทั้งหมด
      </button>
      <button
        className={`text-navy py-2 text-xs sm:text-base font-semibold ${
          type === "lotto" && "bg-gold"
        } rounded-[12px]`}
        onClick={() => setType("lotto")}
      >
        ลอตเตอรี่ทั้งหมด
      </button>

      <button
        className={`text-navy py-2 text-xs sm:text-base font-semibold ${
          type === "history" && "bg-gold"
        } rounded-[12px]`}
        onClick={() => {
          setType("history");
        }}
      >
        ประวัติการซื้อ
      </button>
    </div>
  );
};

const HistoryOrder = ({ orderList }) => {
  const { configs, profile, profileState, callApi, go } = Context();
  const [roundDate, setRoundDate] = useState(configs.roundDate);
  const [orders, setOrders] = useState(orderList || []);
  const [showApprovedOrder, setShowApprovedOrder] = useState(true);
  const [tempLang, setTempLang] = useState("TH");

  const [totalReward, setTotalReward] = useState(0);
  const [rewards, setRewards] = useState([]);
  const [isHasPrize, setIsHasPrize] = useState(false);

  useEffect(() => {
    getOrder();
    switch (profileState?.bank?.currency) {
      case "THB":
        setTempLang("TH");
        break;
      case "LAK":
        setTempLang("LAO");
        break;
      default:
        setTempLang("TH");
    }
  }, []);

  const getOrder = async (roundDate) => {
    setOrders(false);
    const { orderData } = await callApi(
      "orders-getUserOrders",
      { roundDate },
      { notShowLoading: true }
    );

    let tPrizes = {
      THB: 0,
      LAK: 0,
    };

    const prizes = orderData.reduce((acc, order) => {
      order?.prizes?.forEach(({ prizeType, prizeAmount, number, winner }) => {
        const key = prizeType + number;
        acc[key] = acc[key] || {
          type: prizeType,
          number,
          winner,
          amount: {
            THB: 0,
            LAK: 0,
          },
          count: 0,
          lotteries: order.lotteries.filter(
            (lottery) => lottery.number === number
          ),
        };
        acc[key].amount["THB"] += prizeAmount.THB;
        acc[key].amount["LAK"] += prizeAmount.LAK;
        acc[key].count++;
        tPrizes["THB"] += prizeAmount.THB;
        tPrizes["LAK"] += prizeAmount.LAK;
      });
      return acc;
    }, {});

    const finalReward = Object.values(prizes)
      .reduce((acc, reward) => {
        reward.sequence = getKeyTranslated(reward.type).sequence;
        reward.number = [reward.number];
        var f = acc.filter((e) => e.type === reward.type);
        if (f.length > 0) {
          f[0].number = [].concat(f[0].number, reward.number);
          f[0].winner = [].concat(f[0].winner, reward.winner);
          f[0].count += reward.count;
          f[0].amount["THB"] += reward.amount.THB;
          f[0].amount["LAK"] += reward.amount.LAK;
          f[0].lotteries = [...f[0].lotteries, ...reward.lotteries];
        } else {
          acc.push(reward);
        }
        return acc;
      }, [])
      .sort((a, b) => a.sequence - b.sequence);

    setRewards(finalReward);
    setTotalReward(tPrizes);
    setIsHasPrize(!!tPrizes.THB);
    setOrders(orderData || []);
  };

  return (
    <div className="mb-24">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 10px"
        margin="10px 0"
      >
        <div className="flex justify-end gap-3 items-center">
          <p className="font-semibold md:text-2xl  text-right text-white flex-grow">
            ประวัติการซื้อ งวด
          </p>
          <select
            value={roundDate}
            className="border-2 border-gray-300 text-sm h-[34px] font-semibold rounded-lg text-center w-full max-w-48 "
            onChange={(e) => {
              setRoundDate(e.target.value);
              getOrder(e.target.value);
            }}
          >
            {configs.roundDates
              // .filter((date) => date !== configs.nextRoundDate)
              .sort()
              .reverse()
              .slice(0, 4)
              .map((x) => (
                <option value={x} key={x}>
                  {roundDateToDate(x)}
                </option>
              ))}
          </select>
        </div>
        <div className="grid grid-cols-2 bg-white mt-2.5 p-1 shadow-md rounded-lg">
          <button
            className={`text-navy py-2 text-xs sm:text-base font-semibold ${
              showApprovedOrder && "bg-gold"
            } rounded-[12px]`}
            onClick={() => setShowApprovedOrder(true)}
          >
            รายการคำสั่งซื้อที่เสร็จสมบูรณ์
          </button>

          <button
            className={`text-navy py-2 text-xs sm:text-base font-semibold ${
              !showApprovedOrder && "bg-gold"
            } rounded-[12px]`}
            onClick={() => setShowApprovedOrder(false)}
          >
            รายการคำสั่งซื้อที่ถูกยกเลิก
          </button>
        </div>
      </ContentContainer>

      {orders &&
        (orders.filter((order) => order.status === "approved").length === 0 ||
        (configs.roundDate === roundDate && !configs.isAwardReady) ? (
          <></>
        ) : isHasPrize ? (
          <div className="relative h-full w-full md:mt-20  mt-8">
            <p className="text-4xl md:text-[4em] text-gold font-bold text-center">
              {locale.history_congrat[tempLang].split(" ")[0]}
            </p>
            <p className="text-xl md:text-[2em]  mb-8 mt-3 font-bold text-center">
              {" "}
              {locale.history_congrat[tempLang].split(" ")[1]}{" "}
              <span className="text-danger">
                {" "}
                {totalReward[
                  profileState?.bank?.currency || "THB"
                ].toLocaleString("en-US")}
              </span>{" "}
              {locale.currency[tempLang]}
            </p>
            <div className="absolute bottom-0 w-full h-full">
              <Player autoplay loop src={celebrate1}>
                <Controls
                  visible={false}
                  buttons={["play", "repeat", "frame", "debug"]}
                />
              </Player>
            </div>
            <img
              alt="reward"
              src={"/images/reward.png"}
              className="h-full max-h-[40dvh] mx-auto mb-14 drop-shadow-lg"
            />
            {rewards.map((reward, i) => (
              <Reward
                key={i}
                reward={reward}
                tempLang={tempLang}
                profileState={profileState}
              />
            ))}
          </div>
        ) : (
          <div className="relative h-full w-full py-6">
            <p className="text-3xl md:text-[2em] text-gold font-bold text-center">
              งวดนี้ไม่ใช่วันของเรา
            </p>
            <p className="text-base md:text-[1em] mt-2 font-bold text-center">
              รางวัลที่ 1 เจอกันใหม่โอกาสหน้า
            </p>

            <img
              alt="noreward"
              src="/images/prize_no_prize.png"
              className=" mx-auto h-full max-h-[40dvh] drop-shadow-lg"
            />
          </div>
        ))}

      {/* <div className="grid grid-cols-2 bg-white my-3 p-1 shadow-md rounded-lg">
        <button
          className={`text-navy py-2 text-xs sm:text-base font-semibold ${
            showApprovedOrder && "bg-gold"
          } rounded-[12px]`}
          onClick={() => setShowApprovedOrder(true)}
        >
          รายการคำสั่งซื้อที่เสร็จสมบูรณ์
        </button>

        <button
          className={`text-navy py-2 text-xs sm:text-base font-semibold ${
            !showApprovedOrder && "bg-gold"
          } rounded-[12px]`}
          onClick={() => setShowApprovedOrder(false)}
        >
          รายการคำสั่งซื้อที่ถูกยกเลิก
        </button>
      </div> */}

      {orders ? (
        orders.filter((order) =>
          showApprovedOrder
            ? order.status === "approved"
            : order.status !== "approved"
        ).length === 0 ? (
          <div className="h-80 flex justify-center items-center">
            <p className="text-xs font-semibold text-gray-400">
              ไม่พบคำสั่งซื้อ{showApprovedOrder ? "สำเร็จ" : "ถูกยกเลิก"}
              ในงวดนี้
            </p>
          </div>
        ) : (
          orders &&
          orders
            .filter((order) =>
              showApprovedOrder
                ? order.status === "approved"
                : order.status !== "approved"
            )
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((order, i) => {
              return (
                <OrderContainer
                  key={i}
                  order={order}
                  go={go}
                  showImage={true}
                />
              );
            })
        )
      ) : (
        <div className="h-[50dvh] flex justify-center items-center">
          <Spinner className="text-gold" />
        </div>
      )}
    </div>
  );
};

const Reward = ({ reward, tempLang, profileState }) => {
  const [isCollapse, setCollapse] = useState(true);
  return (
    <ContentContainer
      minHeight="0"
      bgcolor="newGrReverse"
      className=" my-6 p-0"
    >
      <div className={`relative p-8  overflow-hidden duration-150 `}>
        <p className="text-white text-3xl font-bold mb-6 text-center">
          รางวัล{getKeyTranslated(reward.type).name} จำนวน {reward.count} ใบ
        </p>

        <div className="w-full grid grid-cols-2 gap-2 z-0">
          <ScrollArea
            className={`w-full h-full max-h-48 sm:max-h-80 duration-150 px-2 mx-2 rounded-lg`}
          >
            <StackImages
              urls={reward.lotteries.map((x) => [x.image, x.isMagicNumber])}
            />
          </ScrollArea>
          <div className="flex flex-col justify-start items-center gap-3">
            <p className="text-gold text:base sm:text-4xl font-bold mt-6 sm:mt-12 ">
              มูลค่ารวม{" "}
              {reward.amount[
                profileState.bank?.currency || "THB"
              ].toLocaleString("en-US")}{" "}
              {locale.currency[tempLang]}
            </p>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};

const AllOrder = ({ orderList, go }) => {
  if (!orderList) {
    return (
      <div className="h-[50dvh] flex justify-center items-center">
        <Spinner className="text-gold" />
      </div>
    );
  }
  if (orderList.length === 0) {
    return (
      <div className="h-80 flex justify-center items-center">
        <p className="text-xs font-semibold text-gray-400">
          ไม่พบคำสั่งซื้อในงวดนี้
        </p>
      </div>
    );
  }
  return (
    <div className="mb-14">
      <div>
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
          margin="10px 0"
        >
          <p className=" font-semibold md:text-xl text-sm text-center text-white">
            ออเดอร์งวดปัจจุบันทั้งหมด จำนวน {orderList.length} ออเดอร์
          </p>
        </ContentContainer>
        {orderList
          .sort((a, b) => b.createdAt - a.createdAt)
          // .filter((order) => order.status !== statuses.CANCEL)
          .map((order, i) => {
            return (
              <OrderContainer key={i} order={order} go={go} showImage={true} />
            );
          })}
      </div>
    </div>
  );
};

const AllLotto = ({ orderList }) => {
  const [isShowAllLotto, setIsShowAllLotto] = useState(false);

  if (!orderList) {
    return (
      <div className="h-[50dvh] flex justify-center items-center">
        <Spinner className="text-gold" />
      </div>
    );
  }

  if (orderList?.filter((x) => x.status === "approved").length === 0) {
    return (
      <div className="h-80 flex justify-center items-center">
        <p className="text-xs font-semibold text-gray-400">
          ไม่พบคำสั่งซื้อสำเร็จในงวดนี้
        </p>
      </div>
    );
  }
  return (
    <div className="mb-14">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <p className=" font-semibold md:text-xl text-sm text-center text-white">
          ลอตเตอรี่งวดปัจจุบันทั้งหมด จำนวน{" "}
          {orderList &&
            orderList
              ?.filter((x) => x.status === "approved")
              .reduce((acc, x) => {
                return [...acc, ...x.lotteries.map((x) => x)];
              }, []).length}{" "}
          ใบ
        </p>
      </ContentContainer>

      <ContentContainer padding="32px 60px">
        <div className="flex justify-center items-center gap-4">
          <button
            className={`p-2 ${
              isShowAllLotto ? "" : "bg-gold text-navy font-bold"
            } rounded-xl`}
            onClick={() => setIsShowAllLotto(false)}
          >
            <HiOutlineSquare3Stack3D className="w-16 h-16" />
            <p className="text-xs mt-1">ซ้อน</p>
          </button>
          <button
            className={`p-2 ${
              isShowAllLotto ? "bg-gold text-navy font-bold" : ""
            } rounded-xl`}
            onClick={() => setIsShowAllLotto(true)}
          >
            <TbLayoutList className="w-16 h-16" />
            <p className="text-xs  mt-1">เรียง</p>
          </button>
        </div>

        <Div margin="50px auto" maxWidth="460px">
          <StackImages
            isShowAllLotto={isShowAllLotto}
            urls={
              orderList &&
              orderList
                ?.filter((x) => x.status === "approved")
                .reduce((acc, x) => {
                  return [
                    ...acc,
                    ...x.lotteries.map((x) => [x.image, x.isMagicNumber]),
                  ];
                }, [])
            }
          />
        </Div>
      </ContentContainer>
    </div>
  );
};
