import React, { useState, useRef, useEffect } from "react";
import { devices, getThaiDateFormat } from "../constants";

import styled from "styled-components";
import Context from "../Context";
import { Button, Flex, Div, Typo, ContentContainer } from "../components";
import { FiSearch } from "react-icons/fi";
import themes from "../themes";
import { SquareInput } from "./SquareInputs";
import * as Scroll from "react-scroll";
import { Profile } from "../pages";
export const SearchBox = ({
  digitsPath,
  filterPath,
  filterStatus,
  refresh,
}) => {
  const [digits, setDigits] = useState(
    (digitsPath || "xxxxxx").split("").map((x) => (x !== "x" ? x : ""))
  );
  const [filter, setFilter] = useState(filterPath || filterStatus.all);
  const { go, configs, showModalLogin, profileState } = Context();
  const [, setTs] = useState(Date.now());
  const isLogin = localStorage.getItem("accessToken");
  let scroll = Scroll.animateScroll;

  const handleSearch = () => {
    const ds = digits.reduce((acc, x, i) => {
      acc[`d${i + 1}`] = x;
      return acc;
    }, {});
    const body = {};
    Object.keys(ds).forEach((dx) => {
      if (ds[dx]) {
        body[dx] = ds[dx];
      }
    });
    const path = digits.map((x) => x || "x").join("");
    go(`/search/${filter}/${path}`);
    scroll.scrollMore(500, { duration: 0 });
  };

  const handleFilter = (status) => {
    setFilter(status);
    go(`/search/${status}`);
  };
  return (
    <ContentContainer>
      <Div>
        <Typo.H4 className="text-center" color="navy">
          ค้นหาเลขเด็ด โดนใจของคุณ!
        </Typo.H4>
        <Flex
          margin="24px 0"
          width="100%"
          align="center"
          justify="space-between"
        >
          <Typo.H7 color="textBlack">
            งวดวันที่ {getThaiDateFormat(new Date(configs.roundDate))}
          </Typo.H7>
          <Typo.Body4
            color="red"
            cursor="pointer"
            onClick={() => setDigits(["", "", "", "", "", ""])}
          >
            <u>รีเซ็ต</u>
          </Typo.Body4>
        </Flex>

        <div className="grid mx-auto grid-cols-6 max-w-[640px]">
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
          ${digits[0] !== "" ? "text-navy border-navy" : "border-gray-200"} 
        focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"1"}
            index={0}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
              ${digits[1] !== "" ? "text-navy border-navy" : "border-gray-200"} 
            focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"2"}
            index={1}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
          ${digits[2] !== "" ? "text-navy border-navy" : "border-gray-200"} 
        focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"3"}
            index={2}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
            ${digits[3] !== "" ? "text-navy border-navy" : "border-gray-200"} 
          focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"4"}
            index={3}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
            ${digits[4] !== "" ? "text-navy border-navy" : "border-gray-200"} 
          focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"5"}
            index={4}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
          <SquareInput
            className={`w-12 h-12 sm:w-20 sm:h-16 border-3 text-xl sm:text-3xl
            ${digits[5] !== "" ? "text-navy border-navy" : "border-gray-200"} 
          focus:border-3 focus:border-navy rounded-md text-center placeholder:text-xl placeholder:text-gray-200 font-medium outline-none`}
            text={"6"}
            index={5}
            digits={digits}
            setDigits={setDigits}
            setTs={setTs}
            action={handleSearch}
          />
        </div>

        <div className="grid grid-cols-3 gap-2 my-2 mt-4">
          <button
            className={`py-2 
              ${
                filter === filterStatus.all
                  ? "bg-navy text-white"
                  : "bg-navy2 text-blue-300"
              }
            
            text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.all)}
          >
            ดูทั้งหมด
          </button>

          <button
            className={`${configs.showButton?.one ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.one
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.one)}
          >
            ใบเดี่ยว
          </button>

          <button
            className={`${configs.showButton?.two ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.two
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.two)}
          >
            ชุด 2 ใบ
          </button>

          <button
            className={`${configs.showButton?.three ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.three
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.three)}
          >
            ชุด 3 ใบ
          </button>
          <button
            className={`${configs.showButton?.four ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.four
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.four)}
          >
            ชุด 4 ใบ
          </button>
          <button
            className={`${configs.showButton?.five ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.five
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.five)}
          >
            ชุด 5 ใบ
          </button>
          <button
            className={`${configs.showButton?.random ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.random
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.random)}
          >
            สุ่มเลข
          </button>
          <button
            className={`${configs.showButton?.bigNumber ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.big
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.big)}
          >
            ชุดใหญ่
          </button>

          <button
            className={`${configs.showButton?.prettyFronts ? "py-2" : "hidden"} 
             ${
               filter === filterStatus.prettyFronts
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => handleFilter(filterStatus.prettyFronts)}
          >
            เลขหน้าสวย
          </button>

          <button
            className={`${
              configs.showButton?.specialNumber ? "py-2" : "hidden"
            } 
             ${
               filter === filterStatus.specialNumber
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
            onClick={() => {
              if (!isLogin) {
                showModalLogin({ isShow: true });
                return;
              }
              setDigits(["", "", "", "", "", ""]);
              setFilter("specialNumber");
              go(`/search/specialNumber`);
            }}
          >
            เลขเซียนดัง
          </button>

          {
            <button
              className={`${
                configs.showButton?.specialEvent ? "py-2" : "hidden"
              } 
             ${
               filter === filterStatus.specialEvent
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
              onClick={() => handleFilter(filterStatus.specialEvent)}
            >
              เลขบุญอุ้มเงินล้าน
            </button>
          }
        </div>
        <button
          className={`${
            configs.showButton?.magicNumber ? "w-full py-2" : "hidden"
          } 
             ${
               filter === filterStatus.magicNumber
                 ? "bg-navy text-white"
                 : "bg-navy2 text-blue-300 hover:opacity-80"
             }
           
           text-sm font-medium rounded-md`}
          onClick={() => {
            setDigits(["", "", "", "", "", ""]);
            setFilter("magicNumber");
            go(`/search/magicNumber`);
          }}
        >
          {configs.magicNumber?.name}
        </button>

        <button
          className="mt-4 py-2 rounded-md hover:opacity-80 bg-navy text-white font-semibold w-full"
          onClick={() => handleSearch()}
        >
          ค้นหา
        </button>
      </Div>
    </ContentContainer>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  margin: 32px 0;
  grid-gap: 16px;

  //ipad size
  @media only screen and (${devices.tablet}) {
  }
  //iphone size

  @media only screen and (${devices.mobile}) {
    gap: 8px;
  }
`;
