import React, { useEffect, useContext, useState } from "react";
import { FormFeedback, Row, Col, Modal, ModalBody } from "reactstrap";
import {
  Label,
  Flex,
  Button,
  Banner,
  Typo,
  Container,
  Div,
  Input,
  ContentContainer,
  Line,
} from "../components";
import themes from "../themes";
import styled from "styled-components";
import * as R from "ramda";
import { validate, getThaiDateFormat, devices } from "../constants";
import Context from "../Context";
import { Helmet } from "react-helmet";
import { getKeyTranslated } from "../util";
import { FiSearch, FiDownload } from "react-icons/fi";

export const CheckReward = () => {
  const { callApi, configs } = Context();
  const [isShowCheckReward, setShowCheckReward] = useState(true);
  const [downloadLink, setDownloadLink] = useState("");
  const [roundDate, setRoundDate] = useState(
    configs.isAwardReady
      ? configs.roundDate
      : configs.roundDates
          .filter(
            (roundDate) =>
              roundDate !== configs.roundDate &&
              roundDate !== configs.nextRoundDate
          )
          .sort()
          .reverse()[0]
    // configs.isAwardReady
    //   ? configs.roundDate
    //   : configs.roundDates
    //       .filter((roundDate) => roundDate !== configs.roundDate)
    //       .sort()[0]
  );

  const [prizes, setPrizes] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getRoundPrize();
  }, [roundDate]);

  const getRoundPrize = () => {
    return callApi("prizes-getRoundPrizes", { roundDate }).then((result) => {
      setDownloadLink(result.download || "");

      setPrizes(
        Object.keys(result)
          .filter((key) => key !== "download")
          .reduce((newObj, key) => {
            newObj[key] = result[key];
            return newObj;
          }, {})
      );
    });
  };

  return (
    <div className="py-12">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.hongthongofficial.com/ตรวจรางวัลลอตเตอรี่"
        />
      </Helmet>
      <Container margin="0 auto">
        <Flex
          height="100%"
          direction="column"
          align="center"
          justify="space-between"
        >
          <Flex direction="column" align="center">
            <Typo.H3 color="navy" margin=" 0">
              ตรวจรางวัลสลากกินแบ่งรัฐบาล
            </Typo.H3>
            <div className="grid grid-cols-2 w-full bg-white my-2 p-1 shadow-md rounded-lg">
              <button
                className={`text-navy py-2 text-base font-semibold ${
                  isShowCheckReward && "bg-yellow-400"
                } rounded-[12px]`}
                onClick={() => setShowCheckReward(true)}
              >
                ตรวจผลรางวัล
              </button>
              <button
                className={`text-navy py-2 text-base font-semibold ${
                  isShowCheckReward || "bg-yellow-400"
                } rounded-[12px]`}
                onClick={() => setShowCheckReward(false)}
              >
                รายการรางวัล
              </button>
            </div>
            {/* <Flex
              justify="center"
              width="100%"
              height="68px"
              background="white"
              radius="60px"
              padding="8px"
            >
              <Button
                grow={1}
                background={isShowCheckReward && "yellow"}
                hoverBg={!isShowCheckReward && "white"}
                onClick={() => setShowCheckReward(true)}
                radius="60px"
              >
                <Typo.H6
                  color="navy"
                  weight="bold"
                  hoverColor={!isShowCheckReward && "yellow"}
                >
                  ตรวจผลรางวัล
                </Typo.H6>
              </Button>
              <Button
                grow={1}
                background={!isShowCheckReward && "yellow"}
                hoverBg={isShowCheckReward && "white"}
                onClick={() => setShowCheckReward(false)}
                radius="60px"
              >
                <Typo.H6
                  color="navy"
                  weight="bold"
                  hoverColor={isShowCheckReward && "yellow"}
                >
                  รายการรางวัล
                </Typo.H6>
              </Button>
            </Flex> */}

            {isShowCheckReward ? (
              <CheckRewardPanel
                configs={configs}
                roundDates={configs.roundDates}
                roundDate={roundDate}
                setRoundDate={setRoundDate}
                prizes={prizes}
              />
            ) : (
              <RewardListPanel
                configs={configs}
                prizes={prizes}
                roundDates={configs.roundDates}
                roundDate={roundDate}
                setRoundDate={setRoundDate}
                downloadLink={downloadLink}
              />
            )}
          </Flex>
        </Flex>
      </Container>
    </div>
  );
};

const ModalReward = ({ showModal, setModal, rewards }) => {
  const isReward = rewards.filter((reward) => reward.isReward).length > 0;
  return (
    <Modal
      centered
      isOpen={showModal}
      toggle={() => {
        setModal(false);
      }}
    >
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0",
          minHeight: "500px",
        }}
      >
        <img
          src={`/images/${
            isReward ? "modal-reward.svg" : "modal-no-reward.svg"
          }`}
          style={{ width: "100%" }}
          alt="reward"
        />
        <Flex
          direction="column"
          justify="space-between"
          align="center"
          padding="32px"
          grow={1}
          gap="15px"
        >
          <Flex width="100%" justify="center" gap="5px" wrap="wrap">
            <Typo.H4 color="yellow" weight="bold">
              {isReward ? "ยินดีด้วย!" : "เสียใจด้วย"}
            </Typo.H4>
            <Typo.H5 color="navy" weight="bold">
              {isReward ? "ท่านถูกรางวัล" : "ท่านไม่ถูกรางวัล"}
            </Typo.H5>
          </Flex>
          {isReward ? (
            <Typo.Body1 color="textBlack" textAlign="center" weight="bold">
              เลข
              <span style={{ color: themes.colors.navy }}>
                {rewards
                  .filter((reward) => reward.isReward)
                  .map((number, index) =>
                    index === 0
                      ? " " + number.value + " "
                      : "/ " + number.value + " "
                  )}
              </span>{" "}
              <br />
              {rewards.map((number) =>
                number.message.map((message) => (
                  <span>
                    {message}
                    <br />
                  </span>
                ))
              )}
            </Typo.Body1>
          ) : (
            <Typo.Body1 color="textBlack" weight="bold">
              เลข
              <span style={{ color: themes.colors.navy }}>
                {rewards.map((number, index) =>
                  index === 0
                    ? " " + number.value + " "
                    : "/ " + number.value + " "
                )}
              </span>
              ไม่ถูกรางวัลในงวดนี้
            </Typo.Body1>
          )}
          <Button
            height="48px"
            width="100%"
            background="gradient2"
            size="18px"
            weight="bold"
            hoverBg="white"
            hoverBorder="navy"
            hoverColor="navy"
            onClick={() => setModal(false)}
          >
            ปิด
          </Button>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

const CheckRewardPanel = ({
  configs,
  roundDates,
  setRoundDate,
  roundDate,
  prizes,
  downloadLink,
}) => {
  const [inputs, setInputs] = useState({
    input1: { value: "", error: false, message: "" },
    input2: { value: "", error: false, message: "" },
    input3: { value: "", error: false, message: "" },
  });
  const [rewards, setRewards] = useState([]);
  const [showModal, setModal] = useState(false);

  const checkReward = (inputs) => {
    let newState = Object.values(inputs);
    //clear old message so it wont duplicate cal
    const returnState = (state, ep) => {
      return {
        ...state,
        isReward: true,
        message: [
          ...state.message,
          `ถูกรางวัล${ep.name} มูลค่า  
                ${ep.prizeAmount.toLocaleString("en-US")}
                 บาท`,
        ],
      };
    };

    // check full number to all prizes
    Object.entries(prizes)
      .map(([key, value]) => ({
        ...value,
        name: getKeyTranslated(key).name,
        sequence: getKeyTranslated(key).sequence,
      }))
      .sort((a, b) => a.sequence - b.sequence)
      .forEach((eachPrize) => {
        if (eachPrize.digitType === "number") {
          eachPrize.numbers.forEach(
            (number) =>
              (newState = newState.map((state) =>
                state.value === number
                  ? returnState(state, eachPrize)
                  : { ...state }
              ))
          );
        } else if (eachPrize.digitType === "firstThree") {
          eachPrize.numbers.forEach(
            (number) =>
              (newState = newState.map((state) =>
                state.value.slice(0, 3) === number
                  ? returnState(state, eachPrize)
                  : { ...state }
              ))
          );
        } else if (eachPrize.digitType === "lastThree") {
          eachPrize.numbers.forEach(
            (number) =>
              (newState = newState.map((state) =>
                state.value.slice(3, 6) === number
                  ? returnState(state, eachPrize)
                  : { ...state }
              ))
          );
        } else if (eachPrize.digitType === "lastTwo") {
          eachPrize.numbers.forEach(
            (number) =>
              (newState = newState.map((state) =>
                state.value.slice(4, 6) === number
                  ? returnState(state, eachPrize)
                  : { ...state }
              ))
          );
        }
      });

    // inject no match message to all empty message with value
    newState = newState.filter(
      (eachState) =>
        isValid(eachState.value) && { ...eachState, isReward: false }
    );
    setRewards(newState);
  };

  const submitReward = async () => {
    if (Object.values(inputs).every((state) => R.isEmpty(state.value))) {
      return;
    }
    if (
      Object.values(inputs).every(
        (state) => isValid(state.value) || R.isEmpty(state.value)
      )
    ) {
      await checkReward(inputs);

      setModal(true);
      return;
    }
    return;
  };

  const isValid = (value) => {
    return value.length === 6;
  };

  return (
    <Flex direction="column" align="center">
      <ModalReward
        showModal={showModal}
        setModal={setModal}
        rewards={rewards}
      />
      <ContentContainer paddingMobile="16px">
        <Flex direction="column" align="center">
          <Flex direction="column" maxWidth="620px" width="100%">
            <Input
              name="select"
              type="select"
              onChange={(e) => setRoundDate(e.target.value)}
              weight="regular"
              radius="8px"
              value={roundDate}
            >
              {roundDates
                .sort()
                .reverse()
                .filter((roundDate) =>
                  !configs.isAwardReady
                    ? roundDate !== configs.roundDate
                    : roundDate
                )
                .map((value, index) => (
                  <option key={index} value={value}>
                    งวดวันที่ {getThaiDateFormat(new Date(value))}
                  </option>
                ))}
            </Input>
            <Line />
            <InputContainer>
              <Input
                name="input1"
                radius="8px"
                placeholder="กรุณากรอก"
                weight="regular"
                type="text"
                value={inputs.input1.value}
                maxLength={6}
                onChange={(e) => {
                  if (validate.lotteryLength.test(e.target.value)) {
                    setInputs((prevState) => ({
                      ...prevState,
                      input1: {
                        ...prevState.input1,
                        value: e.target.value,
                      },
                    }));
                  }
                }}
                onBlur={(e) =>
                  setInputs((prevState) => ({
                    ...prevState,
                    input1: {
                      ...prevState.input1,
                      error:
                        e.target.value.length !== 6 &&
                        e.target.value.length !== 0,
                    },
                  }))
                }
                invalid={inputs.input1.error}
              />
              <FormFeedbackStyle valid={!inputs.input1.error}>
                กรุณากรอกหมายเลขสลาก 6 ตัว
              </FormFeedbackStyle>
            </InputContainer>
            <InputContainer>
              <Input
                name="input2"
                radius="8px"
                placeholder="กรุณากรอก"
                weight="regular"
                type="text"
                value={inputs.input2.value}
                maxLength={6}
                onChange={(e) => {
                  if (validate.lotteryLength.test(e.target.value)) {
                    setInputs((prevState) => ({
                      ...prevState,
                      input2: {
                        ...prevState.input2,
                        value: e.target.value,
                      },
                    }));
                  }
                }}
                onBlur={(e) =>
                  setInputs((prevState) => ({
                    ...prevState,
                    input2: {
                      ...prevState.input2,
                      error:
                        e.target.value.length !== 6 &&
                        e.target.value.length !== 0,
                    },
                  }))
                }
                invalid={inputs.input2.error}
              />
              <FormFeedbackStyle valid={!inputs.input2.error}>
                กรุณากรอกหมายเลขสลาก 6 ตัว
              </FormFeedbackStyle>
            </InputContainer>
            <InputContainer>
              <Input
                name="input3"
                radius="8px"
                placeholder="กรุณากรอก"
                weight="regular"
                type="text"
                value={inputs.input3.value}
                maxLength={6}
                onChange={(e) => {
                  if (validate.lotteryLength.test(e.target.value)) {
                    setInputs((prevState) => ({
                      ...prevState,
                      input3: {
                        ...prevState.input3,
                        value: e.target.value,
                      },
                    }));
                  }
                }}
                onBlur={(e) =>
                  setInputs((prevState) => ({
                    ...prevState,
                    input3: {
                      ...prevState.input3,
                      error:
                        e.target.value.length !== 6 &&
                        e.target.value.length !== 0,
                    },
                  }))
                }
                invalid={inputs.input3.error}
              />
              <FormFeedbackStyle valid={!inputs.input3.error}>
                กรุณากรอกหมายเลขสลาก 6 ตัว
              </FormFeedbackStyle>
            </InputContainer>
            <Button
              width="100%"
              margin="10px 0 0 0"
              background="gradient2"
              color="white"
              weight="bold"
              size="18px"
              hoverBg="white"
              hoverColor="navy"
              hoverBorder="navy"
              onClick={() => submitReward()}
            >
              <FiSearch
                style={{ margin: "0 5px", color: themes.colors.yellow }}
              />
              ตรวจผลรางวัล
            </Button>
          </Flex>
        </Flex>
      </ContentContainer>
      <Banner />
    </Flex>
  );
};

const RewardListPanel = ({
  configs,
  prizes,
  roundDate,
  roundDates,
  setRoundDate,
  downloadLink,
}) => {
  return (
    <ContentContainer>
      <Flex
        className="responsive-column responsive-align-center"
        justify="space-between"
        wrap="wrap"
        margin="10px 0"
        gap="20px"
        width="100%"
      >
        <Div width="100%" maxWidth="236px">
          <Input
            name="select"
            type="select"
            onChange={(e) => setRoundDate(e.target.value)}
            value={roundDate}
          >
            {roundDates
              .sort()
              .reverse()
              .filter((roundDate) =>
                !configs.isAwardReady
                  ? roundDate !== configs.roundDate
                  : roundDate
              )
              .map((value, index) => (
                <option key={index} value={value}>
                  งวดวันที่ {getThaiDateFormat(new Date(value))}
                </option>
              ))}
          </Input>
        </Div>
        <a href={downloadLink} download rel="noreferrer" target="_blank">
          <Button
            background={downloadLink && "lightNavy"}
            color={downloadLink && "navy"}
            hoverBg={downloadLink && "white"}
            hoverBorder={downloadLink && "navy"}
            width="100%"
            maxWidth="236px"
            weight="bold"
            size="16px"
            disabled={!downloadLink}
          >
            <FiDownload
              style={{
                margin: "0 5px",
                color: downloadLink ? themes.colors.navy : themes.colors.white,
              }}
            />{" "}
            ดาวน์โหลดใบตรวจรางวัล
          </Button>
        </a>
      </Flex>

      {prizes ? (
        <Flex margin="35px 0 0 0" direction="column">
          <Flex margin="15px 0" align="flex-end" wrap="wrap" gap="20px">
            <Flex
              direction="column"
              minWidth="250px"
              grow={1}
              align="center"
              gap="10px"
            >
              <Typo.H4 color="navy">รางวัลที่ 1</Typo.H4>
              <Typo.Body3 color="gray">รางวัลละ 6,000,000 บาท</Typo.Body3>
              <Typo.H1 color="textBlack"> {prizes?.first?.numbers[0]}</Typo.H1>
            </Flex>
            <Flex
              direction="column"
              minWidth="250px"
              grow={1}
              align="center"
              gap="10px"
            >
              <Typo.H7 color="navy">เลขหน้า 3 ตัว</Typo.H7>
              <Typo.Body3 color="gray">รางวัลละ 4,000 บาท</Typo.Body3>
              <Typo.H2 color="textBlack">
                {prizes?.firstThree?.numbers.map((value, index) =>
                  index === 0 ? value : " " + value
                )}
              </Typo.H2>
            </Flex>
            <Flex
              direction="column"
              minWidth="250px"
              grow={1}
              align="center"
              gap="10px"
            >
              <Typo.H7 color="navy">เลขท้าย 3 ตัว</Typo.H7>
              <Typo.Body3 color="gray">รางวัลละ 4,000 บาท</Typo.Body3>
              <Typo.H2 color="textBlack">
                {prizes?.lastThree?.numbers.map((value, index) =>
                  index === 0 ? value : " " + value
                )}
              </Typo.H2>
            </Flex>
            <Flex
              direction="column"
              minWidth="250px"
              grow={1}
              align="center"
              gap="10px"
            >
              <Typo.H7 color="navy">เลขท้าย 2 ตัว</Typo.H7>
              <Typo.Body3 color="gray">รางวัลละ 2,000 บาท</Typo.Body3>
              <Typo.H2 color="textBlack">
                {prizes?.lastTwo?.numbers.map((value, index) =>
                  index === 0 ? value : " " + value
                )}
              </Typo.H2>
            </Flex>
          </Flex>
          <Line />
          <Flex
            direction="column"
            minWidth="250px"
            grow={1}
            align="center"
            gap="10px"
          >
            <Typo.H7 color="navy">รางวัลข้างเคียงรางวัลที่ 1</Typo.H7>
            <Typo.Body3 color="gray">2 รางวัล รางวัลละ 100,000 บาท</Typo.Body3>
            <Flex width="100%" margin="25px 0 10px 0" justify="space-around">
              {prizes?.nearBy?.numbers.map((value, index) => (
                <Typo.H7 color="textBlack" key={index}>
                  {value}
                </Typo.H7>
              ))}
            </Flex>
          </Flex>
          <Line />
          <Flex
            direction="column"
            minWidth="250px"
            grow={1}
            align="center"
            gap="10px"
          >
            <Typo.H7 color="navy">รางวัลที่ 2</Typo.H7>
            <Typo.Body3 color="gray">5 รางวัล รางวัลละ 200,000 บาท</Typo.Body3>

            <Div width="100%" margin="25px 0 10px 0">
              <Grid>
                {prizes?.second?.numbers.map((value, index) => (
                  <Typo.H7 color="textBlack" textAlign="center" key={index}>
                    {value}
                  </Typo.H7>
                ))}
              </Grid>
            </Div>
          </Flex>
          <Line />
          <Flex
            direction="column"
            minWidth="250px"
            grow={1}
            align="center"
            justify="center"
            gap="10px"
          >
            <Typo.H7 color="navy">รางวัลที่ 3</Typo.H7>
            <Typo.Body3 color="gray">10 รางวัล รางวัลละ 80,000 บาท</Typo.Body3>
            <Div width="100%" margin="25px 0 10px 0">
              <Grid>
                {prizes?.third?.numbers.map((value, index) => (
                  <Typo.H7 textAlign="center" color="textBlack" key={index}>
                    {value}
                  </Typo.H7>
                ))}
              </Grid>
            </Div>
          </Flex>
          <Line />
          <Flex
            direction="column"
            minWidth="250px"
            grow={1}
            align="center"
            gap="10px"
          >
            <Typo.H7 color="navy">รางวัลที่ 4</Typo.H7>
            <Typo.Body3 color="gray">50 รางวัล รางวัลละ 40,000 บาท</Typo.Body3>
            <Div width="100%" margin="25px 0 10px 0">
              <Grid>
                {prizes?.forth?.numbers.map((value, index) => (
                  <Typo.Body2 textAlign="center" color="textBlack" key={index}>
                    {value}
                  </Typo.Body2>
                ))}
              </Grid>
            </Div>
          </Flex>
          <Line />
          <Flex
            direction="column"
            minWidth="250px"
            grow={1}
            align="center"
            gap="10px"
          >
            <Typo.H7 color="navy">รางวัลที่ 5</Typo.H7>
            <Typo.Body3 color="gray">100 รางวัล รางวัลละ 20,000 บาท</Typo.Body3>
            <Div width="100%" margin="25px 0 0 0">
              <Grid>
                {prizes?.fith?.numbers.map((value, index) => (
                  <Typo.Body2 textAlign="center" color="textBlack" key={index}>
                    {value}
                  </Typo.Body2>
                ))}
              </Grid>
            </Div>
          </Flex>
        </Flex>
      ) : (
        <BoarderFlex height="100%" align="center" justify="center">
          <Text>
            ยังไม่ได้มีการสร้างรางวัลงวดที่{" "}
            {getThaiDateFormat(new Date(roundDate))}
          </Text>
          <br />
          <Text>โปรดรอหรือติดต่อแอดมิน</Text>
        </BoarderFlex>
      )}
    </ContentContainer>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 5px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  }
`;

const Text = styled.p`
  color: ${themes.colors.blue};
  font-weight: 500;
  text-align: ${(props) => props.align || "center"};
  font-size: 22px;
  margin: 0;
  &.header-small {
    font-size: 15px;
  }
  &.reward-small {
    font-weight: 500;
    font-size: 17px;
  }
  @media screen and (max-width: 430px) {
    font-size: 17px;
    &.header-small {
      font-size: 13px;
    }
  }
`;

const BorderContainer = styled.div`
  border: 3px solid ${themes.colors.blue};
  width: 100%;
  min-height: 700px;
  margin-top: -18px;
  border-radius: 25px;
  padding: 30px 0;
`;

const FormFeedbackStyle = styled(FormFeedback)`
  font-size: 12px;
`;

const InputContainer = styled.div`
  margin: 5px 0;
  width: 100%;
`;
const BoarderFlex = styled(BorderContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
