import React, { Children } from "react";
import themes from "../../themes";
import styled from "styled-components";
import { fontWeight, devices } from "../../constants";

const ButtonStyle = styled.button`
  padding: ${(props) => props.padding || "8px 16px"};
  border: 1px solid
    ${(props) => themes.colors[props.border] || "rgb(0, 0, 0, 0)"};
  border-radius: ${(props) => props.radius || "10px"};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  line-height: ${(props) => props.lineHeight};
  background: ${(props) =>
    themes.colors[props.background] || themes.colors.white};
  color: ${(props) => themes.colors[props.color] || themes.colors.white};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || fontWeight.regular};
  flex-grow: ${(props) => props.grow};
  cursor: ${(props) => props.cursor || "pointer"} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  transition-duration: 0.5s;
  gap: ${(props) => props.gap};

  :hover {
    background: ${(props) =>
      themes.colors[props.hoverBg] || themes.colors.yellow};
    color: ${(props) => themes.colors[props.hoverColor]};
    border: 1px solid
      ${(props) => themes.colors[props.hoverBorder] || "rgb(0,0,0,0)"};
    ${(props) =>
      props.disabled &&
      `background: ${themes.colors.gray2}; color:${themes.colors.white}; border: 1px solid ${themes.colors.gray2};`}
  }

  &.active {
    background: ${(props) =>
      themes.colors[props.activeBg] || themes.colors.yellow};
    color: ${(props) => themes.colors[props.activeColor]};
    border: 1px solid
      ${(props) => themes.colors[props.activeBorder] || "rgb(0,0,0,0)"};
    ${(props) =>
      props.disabled &&
      `background: ${themes.colors.gray2}; color:${themes.colors.white}; border: 1px solid ${themes.colors.gray2};`}
  }

  //ipad size
  @media only screen and (${devices.tablet}) {
    padding: ${(props) => props.paddingTablet || "8px 16px"};
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    padding: ${(props) => props.paddingMobile || "8px 8px"};
  }

  ${(props) =>
    props.disabled &&
    `background: ${themes.colors.gray2}; cursor: no-drop !important; color:${themes.colors.white}; border: 1px solid ${themes.colors.gray2};`}
`;

export const Button = (props) => {
  const { children, className, onClick } = props;

  return (
    <ButtonStyle onClick={onClick} {...props} className={className}>
      {children}
    </ButtonStyle>
  );
};
