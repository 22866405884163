import React from "react";
import { Grid } from "../components";
import { ShopItem } from "./";

export const ShopList = ({ blocks, ...rests }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 items-start px-2">
      {blocks
        .filter((x) => x.size)
        .map((block) => (
          <ShopItem key={block.number} {...block} {...rests} />
        ))}
    </div>
  );
};
