import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Context from "../Context";
import themes from "../themes";
import {
  Wrapper,
  Flex,
  Container,
  Typo,
  Input,
  Button,
  Div,
  ContentContainer,
} from "../components";
import { useToast } from "../components/ui";
import { devices, validate } from "../constants";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FiX } from "react-icons/fi";
import { FcCheckmark } from "react-icons/fc";
import { FaTimes, FaCheckCircle } from "react-icons/fa";

import { useParams } from "react-router-dom";

export const Register = () => {
  const { callApi, setProfileState, profileState, go, configs } = Context();
  const { refId, phone } = useParams();

  const { toast } = useToast();
  const [showModal, setModal] = useState({
    policy: false,
    pdpa: false,
  });
  const [registerData, setRegisterData] = useState({
    phone: phone || "",
    // firstName: "",
    // lastName: "",
    // nationality: "TH",
    // password: "",
    // confirm: "",
    accept: false,
    acceptPdpa: false,
  });
  let [searchParams, setSearchParams] = useSearchParams();

  const validRegist =
    registerData.phone &&
    // registerData.firstName &&
    // registerData.lastName &&
    // registerData.password &&
    // registerData.confirm === registerData.password &&
    registerData.accept &&
    registerData.acceptPdpa;

  useEffect(() => {
    if (refId !== "undefined") {
      sessionStorage.removeItem("referrer");
    }
    if (refId) {
      if (profileState.userId) {
        if (profileState.referrer && profileState.referrer === refId) {
          if (profileState.createdAtRound !== configs.roundDate) {
            toast({
              variant: "destructive",
              className: "bg-[#c15b78]",
              title: (
                <div className="flex gap-2 items-center">
                  <FaTimes /> เงื่อนไขกิจกรรม เฉพาะสมาชิกสมัครภายในงวดเท่านั้น
                </div>
              ),
            });
            go("/");
            return;
          }

          sessionStorage.setItem("referrer", refId);
          toast({
            className: "bg-[rgb(59,176,101)]",
            title: (
              <div className="flex gap-2 items-center">
                <FaCheckCircle /> ยินยันรหัสผู้แนะนำ {refId}
              </div>
            ),
          });
          go("/search");
          return;
        }
        toast({
          variant: "destructive",
          className: "bg-[#c15b78]",
          title: (
            <div className="flex gap-2 items-center">
              <FaTimes /> เงื่อนไขกิจกรรม เฉพาะสมาชิกสมัครภายในงวดเท่านั้น
            </div>
          ),
        });
        go("/");
      } else {
        sessionStorage.setItem("referrer", refId);
        toast({
          className: "bg-[rgb(59,176,101)]",
          title: (
            <div className="flex gap-2 items-center">
              <FaCheckCircle /> ยินยันรหัสผู้แนะนำ {refId}
            </div>
          ),
        });
      }
    }
  }, []);

  const handleLogin = async () => {
    const lineId = searchParams.get("lineId");
    const adsKey = searchParams.get("ads");
    if (validRegist) {
      const newUser = {
        ...registerData,
        referrer: refId || false,
        lineId: lineId || "",
        adsKey: adsKey || "",
      };

      const result = await callApi("users-createUser", { newUser });
      if (!result.status) {
        return go("/login");
      }
      setProfileState(result.newUser);
      localStorage.setItem("accessToken", result.accessToken);
      go("/register-otp");
    }
  };

  return (
    <Container className="relative flex items-start justify-center">
      {/* <div
        className="md:hidden absolute top-5 right-5 z-30"
        onClick={() => go("/")}
      >
        <FiX
          style={{
            fontSize: "40px",
            color: themes.colors.white,
            cursor: "pointer",
          }}
        />
      </div> */}
      <img
        className="absolute w-full h-full"
        src="/images/bg-hong.svg"
        alt="logo"
      />
      <ModalPolicy
        showModal={showModal}
        setModal={setModal}
        setRegisterData={setRegisterData}
        registerData={registerData}
      />
      <ModalPdpa
        showModal={showModal}
        setModal={setModal}
        setRegisterData={setRegisterData}
        registerData={registerData}
      />

      <div className="flex flex-col justify-center mt-4 sm:justify-start items-center w-full gap-4 z-10">
        <img
          className="w-full max-w-[456px]"
          src="/images/logodotcom.svg"
          alt="logo"
        />

        <div className="flex flex-col justify-center p-6 items-center bg-white rounded-md w-full max-w-[500px] gap-3">
          <p className="text-xl font-semibold text-navy text-center">
            สมัครสมาชิก
          </p>

          <div className="w-full">
            <Typo.Body6 color={"textBlack"}>เบอร์โทรศัพท์</Typo.Body6>
            <Input
              type="text"
              placeholder="0xx-xxx-xxxx"
              value={registerData.phone}
              onChange={(e) => {
                if (validate.digitOnly.test(e.target.value)) {
                  setRegisterData((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }));
                }
              }}
            />
          </div>

          <Policy accept={registerData.accept} setModal={setModal} />
          <Pdpa accept={registerData.acceptPdpa} setModal={setModal} />
          <Button
            disabled={!validRegist}
            width="100%"
            weight="bold"
            background={"gradient2"}
            onClick={() => handleLogin()}
          >
            สมัครสมาชิก
          </Button>
          <p
            onClick={() => go("/login")}
            className={`text-sm text-navy font-medium opacity-65 hover:opacity-100`}
          >
            เข้าสู่ระบบ
          </p>
        </div>
      </div>
    </Container>
  );
};

const Pdpa = ({ accept, setModal }) => {
  return (
    <Flex width="100%" justify="flex-start" align="flex-start" gap="15px">
      <Div>
        <input
          id="accept"
          type={"checkbox"}
          checked={accept}
          style={{
            textAlign: "left",
            width: "15px",
            height: "15px",
            verticalAlign: "sub",
            borderRadius: "5px",

            cursor: "pointer",
          }}
          onChange={() => {
            setModal((prevState) => ({
              ...prevState,
              pdpa: !prevState.pdpa,
            }));
          }}
        />
      </Div>

      <Flex
        onClick={() => {
          setModal((prevState) => ({
            ...prevState,
            pdpa: !prevState.pdpa,
          }));
        }}
      >
        <p className="text-sm font-medium">
          ข้อกำหนดและเงื่อนไขยินยอมให้มีการเก็บใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
          ของเจ้าของข้อมูลส่วนบุคคล (PDPA)
        </p>
      </Flex>
    </Flex>
  );
};
const Policy = ({ accept, setModal }) => {
  return (
    <Flex width="100%" justify="flex-start" align="center" gap="15px">
      <Div>
        <input
          id="accept"
          type={"checkbox"}
          checked={accept}
          style={{
            textAlign: "left",
            width: "15px",
            height: "15px",
            verticalAlign: "sub",
            borderRadius: "5px",

            cursor: "pointer",
          }}
          onChange={() => {
            setModal((prevState) => ({
              ...prevState,
              policy: !prevState.policy,
            }));
          }}
        />
      </Div>

      <Flex
        onClick={() => {
          setModal((prevState) => ({
            ...prevState,
            policy: !prevState.policy,
          }));
        }}
      >
        <p className="text-sm font-medium">ข้อตกลงและเงื่อนไขของแอปหงษ์ทอง</p>
      </Flex>
    </Flex>
  );
};

const ModalPdpa = ({ showModal, setModal, setRegisterData, registerData }) => {
  const [disable, setDisable] = useState(!registerData.accept);

  return (
    <Modal
      className="my-12"
      centered
      isOpen={showModal.pdpa}
      toggle={() => {
        setDisable(true);
        setRegisterData((prevState) => ({ ...prevState, acceptPdpa: false }));
        setModal((prevState) => ({
          ...prevState,
          pdpa: false,
        }));
      }}
    >
      <ContentContainer padding="8px">
        <ModalHeader>
          <Typo.Body2 color={"navy"}>
            ข้อกำหนดและเงื่อนไขยินยอมให้มีการเก็บใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
            ของเจ้าของข้อมูลส่วนบุคคล (PDPA)
          </Typo.Body2>
        </ModalHeader>
        <ModalBody>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง บริษัท หงษ์ทอง
            ลอตเตอรี่ออนไลน์ จำกัด กับผู้ซื้อหรือผู้รับบริการ
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            1. "ผู้ซื้อ" หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
            ตลอดจนผู้มีส่วนเกี่ยวข้อง
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            2. "บริการ"
            หมายถึงบริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            3. "ผู้ให้บริการ" หมายถึง บริษัท หงษ์ทองลอตเตอรี่ออนไลน์ จำกัด
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            4. "บริษัทฯ" หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            5. "สลากกินแบ่งรัฐบาล" หมายถึง
            สลากกันแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่ง รัฐบาลทุกประเภท
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            6. "แพลตฟอร์ม" หมายถึง "https://www.hongthongofficial.com" หรือ "
            https://shop.hongthongofficial.com " หรือ "แอปพลิเคชันหงษ์ทอง.COM"
            ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย
            รับจัดเก็บรับฝากรับขึ้นเงินสลากกินแบ่งรัฐบาลตลอด จนประกอบกิจการอื่น
            ๆ ตามวัตถุประสงค์ของบริษัทฯ
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            7. ผู้ซื้อยันยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
            ตามพระราชบัญญัติ คุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal Data
            Protection Act B.E. 2562 (2019) เพื่อมีไว้ใช้และเปิดเผย
            ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
            เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
            เลขใบอนุญาตขับขี่, เลขประจำตัวผู้เสียภาษี, เลขบัญชีรนาคาร,
            เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตร ประชาชนหรือสำเนาบัตรอื่น
            ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคล
            ได้โดยตัวมันเอง จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่,
            อีเมล์,เลขโทรศัพท์ / ข้อมูลอุปกรณ์ หรือเครื่องมือ เช่น IP address,
            MAC address, Cookie ID / ข้อมูลระบุทรัพย์สินของ บุคคลเช่น
            ทะเบียนรถยนต์, โฉนดที่ดิน /
            ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
            วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
            ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
            ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน / ข้อมูลหมายเลข
            อ้างอิงที่เก็บไว้ในไมโครฟิล์มแม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบด้ชนีข้อมูลอีกระบบหนึ่งก็จะสามารดระบุไปถึงตัวบุคคลได้
            /ข้อมูลการประเมินผลการทำงานหรือ
            ความเห็นของนายจ้างต่อการทำงานของลูกจ้าง / ข้อมูลบันทึกต่าง
            ๆที่ใช้ติดตามตรวจสอบ กิจกรรมต่าง ๆ ของบุคคล เช่น log file
            /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคล อื่นในอินเทอร์เน็ต
            ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
            ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
          </Typo.Body3>

          <Flex
            justify="space-between"
            align="center"
            onClick={() => {
              setDisable(false);
            }}
          >
            <Typo.Body4 cursor="pointer" color="textBlack">
              ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
              การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
              ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
              ตามที่ผู้ให้บริการเห็นสมควร
            </Typo.Body4>
          </Flex>

          <Button
            disabled={!disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            width="100%"
            margin="25px 0 0 0"
            grow={1}
            gap="10px"
            onClick={() => {
              setDisable(false);
            }}
          >
            <FcCheckmark style={{ marginBottom: "5px" }} />{" "}
            ข้าพเจ้ารับทราบและยินยอม
          </Button>
        </ModalBody>
        <Flex padding="20px 20px" align="center">
          <Flex justify="center" grow={1}>
            <Typo.Body2
              color="navy"
              onClick={() => {
                setDisable(true);
                setModal((prevState) => ({
                  ...prevState,
                  pdpa: false,
                }));
              }}
              cursor="pointer"
            >
              ย้อนกลับ
            </Typo.Body2>
          </Flex>
          <Button
            disabled={disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            grow={1}
            onClick={() => {
              setRegisterData((prevState) => ({
                ...prevState,
                acceptPdpa: !prevState.acceptPdpa,
              }));

              setModal((prevState) => ({
                ...prevState,
                pdpa: false,
              }));
            }}
          >
            ยอมรับ
          </Button>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};
const ModalPolicy = ({
  showModal,
  setModal,
  setRegisterData,
  registerData,
}) => {
  const [disable, setDisable] = useState(!registerData.accept);

  return (
    <Modal
      className="my-12"
      centered
      isOpen={showModal.policy}
      toggle={() => {
        setDisable(true);
        setRegisterData((prevState) => ({ ...prevState, accept: false }));
        setModal((prevState) => ({
          ...prevState,
          policy: false,
        }));
      }}
    >
      <ContentContainer padding="8px">
        <ModalHeader>
          <Typo.Body2 color={"navy"}>
            ข้อตกลงและเงื่อนไขของแอปหงษ์ทอง
          </Typo.Body2>
        </ModalHeader>
        <ModalBody>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉัน ในฐานะผู้ซื้อลอตเตอรี่
            ตกลงและทราบดีว่าหงษ์ทองไม่จำหน่ายลอตเตอรี่ในสถาน
            ศึกษาและให้กับบุคคลที่อายุต่ำกว่า 20 ปี
            ทางหงษ์ทองขอสงวนสิทธิ์ในการดำเนินคดีตาม กฎหมายสูงสุด
            ทั้งทางแพ่งและอาญากับผู้ที่มาซื้อลอตเตอรี่กับหงษ์ทอง
            โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์หรือซื้อในสถานศึกษา
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉันตกลงยินยอม และยอมรับเงื่อนไขต่าง ๆ ที่ทางหงษ์ทองกำหนดขึ้น
            ทั้งในปัจจุบันและ หรือในอนาคตเพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่
            ว่าไม่ต่ำกว่า 20 ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อและฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทองเป็นเงิน
            10,000 (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่หงษ์ทอง
            ตรวจพบว่า ฉันซื้อลอตเตอรี่ ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20
            ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            หงษ์ทองขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อและฉันตกลงยอมจ่ายค่าปรับให้กับทางหงษ์ทองเป็นเงิน
            10,000 (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่หงษ์ทอง
            ตรวจพบว่า ฉันซื้อลอตเตอรี่ ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20
            ปีบริบูรณ์
          </Typo.Body3>
          <Typo.Body3 margin="0 0 15px 0" weight="light" color={"black"}>
            ฉันตกลงและทราบดีว่าทางหงษ์ทองขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูก
            รางวัล และจะไม่คืนเงินค่ซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่
            ในกรณีที่หงษ์ทองตรวจพบว่า ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์
          </Typo.Body3>
          <Flex justify="center" align="flex-start" margin="1em 0" gap="15px">
            <input
              id="accept"
              type={"checkbox"}
              checked={!disable}
              style={{
                textAlign: "left",
                width: "20px",
                height: "20px",
                verticalAlign: "sub",
                borderRadius: "5px",

                cursor: "pointer",
              }}
              onChange={() => {
                setDisable(!disable);
              }}
            />

            <Flex
              justify="space-between"
              align="center"
              onClick={() => {
                setDisable(!disable);
              }}
            >
              <Typo.Body4 cursor="pointer" color="textBlack">
                ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
                ปัจจุบันฉันมีอายุครบ 20 ปีบริบูรณ์
              </Typo.Body4>
            </Flex>
          </Flex>
        </ModalBody>
        <Flex padding="20px 20px" align="center">
          <Flex justify="center" grow={1}>
            <Typo.Body2
              color="navy"
              onClick={() => {
                setDisable(true);
                setModal((prevState) => ({
                  ...prevState,
                  policy: false,
                }));
              }}
              cursor="pointer"
            >
              ย้อนกลับ
            </Typo.Body2>
          </Flex>
          <Button
            disabled={disable}
            size="18px"
            weight="bold"
            color="green"
            background="lightGreen"
            hoverBorder="green"
            hoverBg="white"
            grow={1}
            onClick={() => {
              setRegisterData((prevState) => ({
                ...prevState,
                accept: !prevState.accept,
              }));

              setModal((prevState) => ({
                ...prevState,
                policy: false,
              }));
            }}
          >
            ยอมรับ
          </Button>
        </Flex>
      </ContentContainer>
    </Modal>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 50px 0;
  @media only screen and (${devices.tablet}) {
    flex-direction: column;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    flex-direction: column;
  }
`;
const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 338px;
  height: 92px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    max-width: 130;
    height: 30;
    margin-bottom: 30px;
    &.absolute {
      height: 500px;
      max-width: 300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  //iphone size
  /* @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  } */
`;
const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  left: 0;
  top: 100px;
  z-index: 0;
  @media only screen and (${devices.tablet}) {
    margin: 0;
    top: 0%;
    width: 100%;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;
