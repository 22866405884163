import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import themes from "../themes";
import {
  Container,
  Flex,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components";
import {
  devices,
  fontWeight,
  isHideNavbar,
  getThaiDateAndTimeFormat,
} from "../constants";
import { Spinner, Collapse } from "reactstrap";
import { useLocation } from "react-router-dom";
import { MdNotificationsActive } from "react-icons/md";
import { shortDate, thaiDate, fullDate } from "../util";
import { IoWarningSharp } from "react-icons/io5";

export const Navbar = () => {
  const { profileState, go, callApi } = Context();
  let location = useLocation();
  const lastUpdate = localStorage.getItem("lastUpdate");
  const currentPath = location.pathname;
  const parentPath = currentPath.split("/")[1];
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const paths = [
    { parentPath: "", name: "หน้าแรก", path: "" },
    { parentPath: "check-reward", name: "ตรวจหวย", path: "check-reward" },
    { parentPath: "search", name: "ซื้อลอตเตอรี่", path: "search" },
    { parentPath: "orders", name: "คำสั่งซื้อ", path: "orders" },
  ];

  const navigateWithValidate = (path) => {
    go(path);
  };

  useEffect(() => {
    getAlert();
  }, [window.location.pathname, lastUpdate?.noti, profileState]);

  const getAlert = async () => {
    let defaultAlert = [];

    // ถ้ามี noti ทุกครั้งที่เปลี่ยนหน้า หรือยังไม่ลบ จะทำฟังชั่นนี้
    if (
      localStorage.getItem("lastUpdate.noti") ||
      localStorage.getItem("guestId")
    ) {
      const { data } = await callApi(
        "users-getUserAlert",
        { guestId: localStorage.getItem("guestId") || false },
        { notShowLoading: true }
      );

      if ((data || []).length) {
        defaultAlert = [...defaultAlert, ...data];
        localStorage.setItem("lastUpdate.noti", Date.now());
      } else {
        localStorage.removeItem("lastUpdate.noti");
      }
    }

    // if (!profileState.dob) {
    //   defaultAlert.push({
    //     header: `  กรุณากรอกข้อมูลวันเดือนปีเกิด `,
    //     body: "เพื่อร่วมกิจกรรมกับหงษ์ทองลอตเตอรี่ เร็วๆนี้",
    //     go: `/profile/info`,
    //   });
    // }
    if (
      !profileState.bank?.accNo ||
      !profileState.bank?.accName ||
      !profileState.bank?.name ||
      localStorage.getItem("guestId")
    ) {
      defaultAlert.push({
        header: `กรุณากรอกข้อมูลบัญชีรับเงินรางวัล`,
        body: "เพื่อรับเงินรางวัลทันทีภายใน 24 ชั่วโมง ในกรณีถูกรางวัล",
        go: `/profile/info`,
      });
    }
    setLoading(false);
    setAlerts(defaultAlert);
  };

  return (
    <HeaderWrapper className={`${isHideNavbar(parentPath) && "hide"}`}>
      <Flex height="100%" align="center">
        <Container>
          <Flex width="100%" justify="space-between" align="center">
            <LogoWrapper>
              <img
                src="/images/logo.png"
                alt="logo"
                style={{ width: "100%" }}
              />
            </LogoWrapper>
            <div className="flex gap-4">
              <div
                className="hidden md:flex gap-4 items-center"
                align="center"
                gap="22px"
              >
                {paths.map((data) => {
                  return (
                    <NavLink
                      onClick={() => navigateWithValidate(`/${data.path}`)}
                      className={parentPath === data.parentPath && "selected"}
                      key={data.name}
                    >
                      {data.name}
                    </NavLink>
                  );
                })}
              </div>
              {profileState.phone ? (
                <div className="flex gap-4 z-50 items-center">
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger>
                      <div className="relative">
                        <div
                          className={`${
                            alerts.length === 0 && "hidden"
                          } absolute -top-3 -right-2 flex justify-center items-center w-6 h-6 bg-red-500 text-white px-0 text-xxs font-bold rounded-full`}
                        >
                          {alerts.length}
                        </div>
                        <MdNotificationsActive className="text-gray-50 text-3xl active:scale-125 duration-75" />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent className="flex flex-col p-0 ">
                      {loading ? (
                        <div className="h-32 flex justify-center items-center">
                          <Spinner className="text-muted" />
                        </div>
                      ) : alerts?.length > 0 ? (
                        <>
                          {alerts.map((alert, index) => {
                            return (
                              <div
                                className={`flex gap-3 p-4 cursor-pointer hover:opacity-70 duration-100 ${
                                  alerts.length !== index + 1 &&
                                  "border-b-2 pb-4"
                                }`}
                                onClick={() => {
                                  go(alert.go);
                                  setOpen(!open);
                                }}
                                key={index}
                              >
                                <div>
                                  <IoWarningSharp className="h-8 w-8" />
                                </div>
                                <div className="flex-grow">
                                  <p className="font-semibold mb-0.5">
                                    {alert.header}
                                  </p>
                                  <p className="text-xs">{alert.body}</p>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="min-h-48 flex flex-col justify-center items-center">
                          <p className="text-base text-muted font-semibold">
                            ไม่พบการแจ้งเตือน
                          </p>
                        </div>
                      )}
                    </PopoverContent>
                  </Popover>

                  <ImageWrapper
                    className={parentPath === "profile" && "selected"}
                    onClick={() => go("/profile")}
                    src={
                      profileState.profileImage || "/images/profile-default.svg"
                    }
                    alt="profile"
                  />
                </div>
              ) : (
                <Button
                  className={parentPath === "login" && "hidden"}
                  height="36px"
                  background={"white"}
                  color={"navy"}
                  onClick={() => go("/login")}
                >
                  เข้่าสู่ระบบ
                </Button>
              )}
            </div>
          </Flex>
        </Container>
      </Flex>
    </HeaderWrapper>
  );
};

const NotificationBar = ({}) => {
  const { alerts, setAlerts, callApi, go } = Context();
  const lastUpdate = localStorage.getItem("lastUpdate");
  const [show, setShow] = useState(false);
  useEffect(() => {
    // getNoti();
  }, [show]);

  // const getNoti = async () => {
  //   const result = await callApi("users-getUserAlert");
  //   setAlerts(result.data);
  //   localStorage.setItem("lastUpdate", Date.now());
  // };
  return (
    <Popover>
      <PopoverTrigger onClick={() => setShow((prevState) => !prevState)}>
        <MdNotificationsActive className="text-gray-50 text-3xl active:scale-125 duration-75" />
      </PopoverTrigger>
      <PopoverContent className="min-w-96">
        {alerts.length > 0 ? (
          alerts.map((alert) => {
            return (
              <div className="flex gap-3" onClick={() => go(alert.go)}>
                <IoWarningSharp className="h-8 w-8" />
                <div>
                  <p className="font-semibold">{alert.header}</p>
                  <p className="text-xs">{alert.body}</p>
                </div>{" "}
              </div>
            );
          })
        ) : (
          <div className="min-h-48 flex flex-col justify-center items-center">
            <p className="text-base text-muted font-semibold">
              ไม่พบการแจ้งเตือน
            </p>
            <p className="text-[0.7em] text-muted">
              ข้อมูลล่าสุด {fullDate(new Date(Number(lastUpdate)))}
            </p>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 96px;
  width: 100%;
  background: ${themes.colors.gradient2};
  z-index: 10;

  box-shadow: 0px 2px 12px 0px rgba(0, 10, 27, 0.07);

  &.hide {
    position: relative;
  }
  //ipad size
  @media only screen and (${devices.tablet}) {
    height: 68px;
    &.hide {
      display: none;
    }
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    height: 60;
    &.hide {
      display: none;
    }
  }
`;

export const LogoWrapper = styled.div`
  position: relative;
  width: 178px;
  height: 60px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    width: 143px;
    height: 48px;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;

const ImageWrapper = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transition-duration: 0.2s;

  :hover {
    border: 2px solid ${themes.colors.yellow};
  }

  &.selected {
    border: 2px solid ${themes.colors.yellow};
  }
  @media only screen and (${devices.tablet}) {
    width: 40px;
    height: 40px;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 32px;
    height: 32px;
  }
`;

const NavLink = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: ${fontWeight.semiBold};
  color: ${themes.colors.white80};
  line-height: 20px;
  cursor: pointer;
  transition-duration: 0.2s;
  :hover {
    color: ${themes.colors.white};
  }
  &.selected {
    font-weight: ${fontWeight.bold};
    color: ${themes.colors.yellow};
  }
`;
