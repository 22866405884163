import React, { useState, useEffect } from "react";
import Context from "../../Context";
import {
  Container,
  Typo,
  Flex,
  Button,
  Grid,
  Loading,
  Badge,
  ContentContainer,
  Input,
  Breadcrumb,
  LoadingIcon,
} from "../../components";
import { GridLoader } from "react-spinners";
import { useToast } from "../../components/ui";
import { Spinner } from "reactstrap";
import {
  getThaiDateAndTimeFormat,
  getThaiDateFormat,
  statusColor,
  statusTranslated,
  statuses,
  validate,
} from "../../constants";
import { IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import themes from "../../themes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { roundDateToDate } from "../../util";
import { Collapse, Modal } from "reactstrap";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
export const CouponNotify = () => {
  const { toast } = useToast();
  const { callApi, go, configs, profileState, update } = Context();
  const { couponId } = useParams();
  const [coupon, setCoupon] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [lastTwo, setLastTwo] = useState("");

  useEffect(() => {
    getCoupon();
  }, [couponId]);

  const getCoupon = async () => {
    if (!couponId) {
      toast({
        className: "bg-[#c15b78]",
        title: `ไม่พบคูปอง`,
      });
      return go("/coupon");
    }
    const { status, data } = await callApi("coupons-getTransaction", {
      orderId: couponId,
    });

    if (status) {
      setCoupon(data);
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `ไม่พบคูปอง`,
      });
      return go("/coupon");
    }
    setLoading(false);
  };

  const addNotify = async () => {
    setLoading(true);
    if (lastTwo.length !== 2) return;

    const { status, message } = await callApi("coupons-addNotify", {
      id: couponId,
      number: lastTwo,
    });
    getCoupon();
    if (status) {
      setLastTwo("");
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `เพิ่มการแจ้งเตือนสำเร็จ`,
      });
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `เพิ่มการแจ้งเตือนไม่สำเร็จ`,
        description: message,
      });
    }
    setLoading(false);
  };
  return (
    <Container>
      <Breadcrumb
        data={[
          { path: "/", name: "หน้าแรก" },
          { path: "/coupon", name: "คูปอง" },
          { name: couponId },
        ]}
      />
      {isLoading && !coupon ? (
        <div className="min-h-[40dvh] flex justify-center items-center">
          <GridLoader color={themes.colors.yellow} />
        </div>
      ) : (
        <div>
          <p
            className={`mt-12 mb-6 text-[${themes.colors.navy}] font-bold text-xl`}
            color="navy"
          >
            คูปองแจ้งเตือนเลขท้ายเข้าสู่ระบบ
          </p>
          <div className="bg-gray-50 p-4 rounded-[0.5rem] shadow-sm">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
              <div>
                <p className="text-sm text-gray-800">ไอดีคูปอง</p>
                <p className="text-sm text-gray-800 font-semibold">
                  {coupon.shortId}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-800">วันที่ซื้อ</p>
                <p className="text-sm text-gray-800 font-semibold">
                  {getThaiDateAndTimeFormat(new window.Date(coupon?.createdAt))}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-800">วันหมดอายุ</p>
                <p className="text-sm text-gray-800 font-semibold">
                  {getThaiDateAndTimeFormat(
                    new window.Date(coupon?.coupon?.expireAt)
                  )}
                </p>
              </div>

              <div>
                <p className="text-sm text-gray-800">สถานะ</p>
                <Badge
                  className="w-36"
                  background={statusColor[coupon?.status]}
                >
                  {statusTranslated[coupon?.status]}
                </Badge>
              </div>
            </div>
            <p className="mt-12 font-semibold">
              เงื่อนไขและข้อกำหนดในการใช้คูปอง
            </p>
            <p className="text-xs">
              1. สามารถใช้คูปองได้จนวินาทีสุดท้ายก่อนปิดระบบการขายในงวดนั้นๆ
            </p>

            <p className="text-xs">2.ไม่สามารถคืนสิทธิ์ หรือคืนเงินได้</p>
            <p className="text-xs">3. ไม่สามารถเปลี่ยนเป็นเงินสดได้</p>
            <p className="text-xs">4. เพิ่มการแจ้งเตือนได้ทีละ 1 ครั้ง</p>
            <p className="text-xs">5. แจ้งเตือนผ่านไลน์เท่านั้น</p>
          </div>

          <div className="mt-12 mb-6 flex justify-between flex-wrap items-center">
            <p
              className={`text-[${themes.colors.navy}] font-bold text-xl`}
              color="navy"
              textAlign=""
            >
              ประวัติการแจ้งเตือน
            </p>
            <div className="flex items-center gap-3">
              <input
                disabled={
                  (coupon.coupon?.notify || []).filter(
                    (x) => x.status === statuses.PENDING
                  ).length >= 1
                }
                type="text"
                className="w-16 h-10 border rounded-xl text-center text-xl font-bold"
                value={lastTwo}
                onChange={(e) => {
                  console.log("e.target.value", e.target.value);

                  new RegExp(/^[0-9]*$/).test(e.target.value) &&
                    e.target.value.length <= 2 &&
                    setLastTwo(e.target.value);
                }}
              />
              <Button
                disabled={
                  (coupon.coupon?.notify || []).filter(
                    (x) => x.status === statuses.PENDING
                  ).length >= 1 || lastTwo.length !== 2
                }
                margin="0"
                background="gradient2"
                color="white"
                weight="bold"
                size="12px"
                hoverBg="white"
                hoverColor="navy"
                hoverBorder="navy"
                onClick={() => addNotify()}
              >
                {isLoading && coupon ? (
                  <Spinner className="w-5 h-5" />
                ) : (
                  "รับการแจ้งเตือน"
                )}
              </Button>
            </div>
          </div>
          {coupon.coupon?.notify?.length > 0 ? (
            <div className="flex flex-col gap-3">
              {coupon.coupon?.notify
                ?.sort((a, b) => b.createdAt - a.createdAt)
                .map((data) => {
                  return (
                    <div
                      key={data.createdAt}
                      className="grid grid-cols-4 bg-white rounded-[0.5rem] shadow-sm p-4"
                    >
                      <div>
                        <p className="text-sm text-gray-800">วัน-เวลาจอง</p>
                        <p className="text-sm text-gray-800 font-semibold">
                          {getThaiDateAndTimeFormat(
                            new window.Date(data.createdAt)
                          )}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-800">เลขท้าย</p>
                        <p className="text-sm text-gray-800 font-semibold">
                          {data.number}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-800">สถานะ</p>
                        <p
                          className={`${
                            data?.status === "pending"
                              ? "text-[#ffb067]"
                              : "text-[rgb(59,176,101)]"
                          } font-bold`}
                        >
                          {data?.status === "pending"
                            ? "กำลังดำเนินการ"
                            : "แจ้งเตือนสำเร็จ"}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-800">
                          วัน-เวลาแจ้งเตือน
                        </p>
                        <p className="text-sm text-gray-800 font-semibold">
                          {data.notifyAt
                            ? getThaiDateAndTimeFormat(
                                new window.Date(data.notifyAt)
                              )
                            : "-"}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="font-medium text-gray-400 flex justify-center items-center min-h-80">
              ไม่พบประวัติการแจ้งเตือน
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
