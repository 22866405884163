import React, { useEffect, useContext } from "react";
import Context from "../Context";

export const Logout = () => {
  const { setProfileState, go, callApi } = Context();
  useEffect(() => {
    console.log("asdfadsffsafasdsfa");
    callApi("users-logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("lastUpdate.noti");
    localStorage.removeItem("lastUpdate.alert");
    localStorage.removeItem("adminToken");
    setProfileState({});
    go("/");
    // eslint-disable-next-line
  }, []);
  return <></>;
};
