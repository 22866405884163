import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as R from "ramda";
import Context from "../Context";
import {
  devices,
  fontWeight,
  isHideFooter,
  isHideDesktopCartFooter,
} from "../constants";
import themes from "../themes";
import { Flex, Container, Button, Typo, Div } from "./index";
import { useLocation } from "react-router-dom";

export const DesktopCartFooter = () => {
  const { callApi, cart, setCart, go, profileState } = Context();
  const [ts, setTs] = useState(Number(Date.now()));

  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (cart.size) {
        setTs(Date.now());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cart.size]);

  const navigateWithValidate = (path) => {
    if (!profileState.userNo && localStorage.guestId === null) {
      go("/login");
      return;
    }

    go(path);
  };

  const currentPath = location.pathname;
  const parentPath = currentPath.split("/")[1];

  if (isHideFooter(parentPath)) {
    return;
  }

  if (isHideDesktopCartFooter(parentPath)) {
    return;
  }

  return (
    <CartFooterWrapper
      className={`${cart?.size > 0 && "show"} hidden md:block`}
    >
      <Container margin="0 auto">
        <Flex align="center" justify="space-between" width="100%">
          <CountDown second={cart?.expireIn || 0} cart={cart} />
          {!["deliverChannel", "cart", "receiverPerson"].includes(
            parentPath
          ) ? (
            <Button
              background="yellow"
              color="navy"
              hoverBg="white"
              hoverColor="yellow"
              hoverBorder="yellow"
              onClick={() =>
                navigateWithValidate(
                  cart.deliverChannel ? "/cart" : "/deliverChannel"
                )
              }
            >
              ไปที่ตะกร้า
            </Button>
          ) : (
            <Button
              background="yellow"
              color="navy"
              hoverBg="white"
              hoverColor="yellow"
              hoverBorder="yellow"
              onClick={() => go("/")}
            >
              เลือกลอตเตอรี่เพิ่ม
            </Button>
          )}
        </Flex>
      </Container>
    </CartFooterWrapper>
  );
};

const CountDown = ({ second = 0, cart }) => {
  second = isNaN(second) ? 0 : second;
  const pattern =
    String(Math.floor(second / 60)).padStart(2, "0") +
    ":" +
    String(second % 60).padStart(2, "0");

  useEffect(() => {
    setTimeout(
      () => document.getElementById("cart-size")?.classList?.add("bounce"),
      0
    );

    document.getElementById("cart-size").classList.remove("bounce");
  }, [cart?.size]);

  return (
    <Flex align="center">
      <Div margin="0 20px">
        <img
          src="/images/cart.svg"
          style={{ width: "55px", fill: themes.colors.yellow }}
          alt="cart"
        />

        <CartSizeWrapper id="cart-size">{cart?.size || 0}</CartSizeWrapper>
      </Div>

      <Flex direction="column">
        <Typo.Body3 color="white">คุณเหลือเวลาในการชำระเงิน</Typo.Body3>
        <Typo.H5 color="yellow">{pattern} นาที</Typo.H5>
      </Flex>
    </Flex>
  );
};

const CartFooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  padding: 0;
  height: 0;
  width: 100%;

  background: ${themes.colors.gradient2};
  transition-duration: 0.5s;
  transform: translateY(100%);
  opacity: 0;

  transition: padding 0.5s ease-out;

  &.show {
    height: 100%;
    padding: 30px 0;
    opacity: 1;

    transform: translateY(0);
  }
`;

const CartSizeWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;

  background-color: red;
  color: white;
  padding: 0 5px;
  border-radius: 10px;

  font-weight: 600;
  text-align: center;

  &.bounce {
    animation: bounce 1s normal;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;
