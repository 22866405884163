import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import themes from "../themes";
import {
  Wrapper,
  Flex,
  Container,
  Typo,
  Input,
  Button,
  Div,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  InputBox,
} from "../components";
import * as R from "ramda";
import ReCAPTCHA from "react-google-recaptcha";
import { FaLine } from "react-icons/fa";
import { Collapse, Spinner } from "reactstrap";
import { devices, validate } from "../constants";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";

const random = () => {
  return Math.floor(Math.random() * 60466175)
    .toString(36)
    .toUpperCase()
    .padStart(5, "0");
};
export const Login = () => {
  const recaptchaRef = React.createRef();
  const { callApi, setProfileState, go, profileState } = Context();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { target } = useParams();
  const [token, setToken] = useState("");
  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });

  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [expireIn, setExpireIn] = useState(
    profileState?.otp?.expireAt - new Date()
  );
  const [isLoading, setisLoading] = useState(false);
  const [otpRef, setOtpRef] = useState(profileState?.otp?.ref || "");

  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const recaptchaToken = await executeRecaptcha("yourAction");
    setToken(recaptchaToken);

    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  useEffect(() => {
    if (profileState.userNo) {
      go("/");
    }
  }, [profileState]);

  const handleLogin = async () => {
    const result = await callApi("users-login", {
      username: loginData.phone,
      password: loginData.password,
      otp: digits.join("") || false,
      otpRef: otpRef || false,
      reCaptchaToken: token,
      userAgent: window.navigator.userAgent || "",
    });
    if (result.success) {
      localStorage.setItem("accessToken", result.data.accessToken);
      setProfileState(result.data.userData);
      target ? go(target) : go("/");
      window.location.reload();
    }
  };

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current.getValue();
    setToken(token);

    // apply to form data
  };

  const requestOTP = async () => {
    setisLoading(true);

    // if (token) {
    const tempOtpRef = random();
    const result = await callApi("users-requestOTPLogin", {
      otpRef: tempOtpRef,
      phone: loginData.phone,
      reCaptchaToken: token,
    });

    setOtpRef(tempOtpRef);
    setExpireIn(result.expireAt - new Date());
    setDigits(["", "", "", "", "", ""]);
    // }
    setisLoading(false);
  };

  return (
    <Container className="relative flex justify-center items-start">
      <img
        className="absolute w-full h-full"
        src="/images/bg-hong.svg"
        alt="logo"
      />
      <div className="flex flex-col justify-start mt-4 items-center h-full w-full z-10">
        <img
          className="w-full max-w-96"
          src="/images/logodotcom.svg"
          alt="logo"
        />
        <p className="text-white text-2xl -mt-8 sm:mb-8 font-semibold ">
          เข้าสู่ระบบ
        </p>

        <OTP
          digits={digits}
          setDigits={setDigits}
          expireIn={expireIn}
          setExpireIn={setExpireIn}
          otpRef={otpRef}
          setOtpRef={setOtpRef}
          handleLogin={handleLogin}
        />

        <Collapse
          className="flex flex-col justify-center mt-4 p-3 items-center shadow-md bg-white rounded-md w-full max-w-[500px]"
          isOpen={!expireIn || expireIn <= 0}
        >
          <div className="w-full mb-2">
            <p className={`text-sm font-semibold ${"text-navy"} duration-150`}>
              เบอร์โทรศัพท์
            </p>

            <input
              className={` ${"border-3 border-navy placeholder:text-0 placeholder:text-sm placeholder:font-normal"} 
              font-semibold rounded-md p-2 w-full text-navy duration-150`}
              type="text"
              placeholder="0XX-XXX-XXXX"
              value={loginData.phone}
              onChange={(e) => {
                if (validate.digitOnly.test(e.target.value)) {
                  setLoginData((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }));
                }
              }}
            />
          </div>
          <Collapse className="w-full" isOpen={isShowPassword}>
            <p
              className={`text-sm mt-2 font-semibold ${"text-navy"} duration-150`}
            >
              รหัสผ่าน
            </p>
            <Input.Password
              className="border-3 border-navy mb-3  placeholder:text-0 placeholder:text-sm placeholder:font-normal"
              placeholder="กรุณากรอก"
              onChange={(e) =>
                setLoginData((prevState) => ({
                  ...prevState,
                  password: e.target.value,
                }))
              }
            />
            <p
              className="text-xs text-right text-navy font-medium mt-2 mr-2 cursor-pointer"
              onClick={() => go("reset-password")}
            >
              ลืมรหัสผ่าน
            </p>
          </Collapse>
          {/* <form className="my-3" onSubmit={onSubmitWithReCAPTCHA}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LepvVYqAAAAAOZudU8U6HwAe1h2AFpxtQCx7R0i"
              onChange={() => onSubmitWithReCAPTCHA()}
            />
          </form> */}
          {/* <button className="py-2 w-full bg-navy text-white rounded-md font-semibold">
            เข้าสู่ระบบ
          </button> */}
          <Button
            className={``}
            disabled={
              isShowPassword
                ? !loginData.phone || !loginData.password || isLoading
                : !loginData.phone
            }
            width="100%"
            weight="bold"
            background={"gradient2"}
            onClick={
              () =>
                // (!token ? handleReCaptchaVerify() :
                isShowPassword ? handleLogin() : requestOTP()
              // )
            }
          >
            {isLoading ? <Spinner className="h-4 w-4" /> : "เข้่าสู่ระบบ"}
          </Button>
          <p className="text-xs text-gray-400 font-medium my-3">หรือ</p>
          <div className="grid grid-cols-2 gap-2 w-full ">
            <button
              className="bg-[#06C755] flex justify-center items-center gap-2 w-full text-white text-sm font-semibold py-2 px-2 rounded-md"
              onClick={() =>
                window.location.replace(
                  "https://www.hongthongofficial.com/api/authen/lineLogin"
                )
              }
            >
              เข้่าสู่ระบบด้วย <FaLine className="text-2xl" />
            </button>
            <div
              className="flex items-center justify-center"
              onClick={() => setIsShowPassword(!isShowPassword)}
            >
              <p className={`text-sm text-navy font-medium`}>
                {isShowPassword
                  ? "เข้าสู่ระบบด้วย OTP"
                  : "  เข้่าสู่ระบบด้วยรหัสผ่าน"}
              </p>
            </div>
          </div>
          <p
            onClick={() => go("register")}
            className={`text-sm mt-3 text-navy font-medium opacity-65 hover:opacity-100`}
          >
            ยังไม่เป็นสมาชิก? สมัครเลย
          </p>

          {/* 
          <Button
            width="100%"
            background={"greenLine"}
            weight="bold"
            onClick={() =>
              window.location.replace(
                "https://www.hongthongofficial.com/api/authen/lineLogin"
              )
            }
          >
            เข้่าสู่ระบบผ่าน LINE
          </Button>
          <Button
            disabled={!loginData.phone || !token}
            width="100%"
            weight="bold"
            background={"gradient2"}
            onClick={() => handleLogin()}
          >
            เข้่าสู่ระบบ
          </Button> */}
        </Collapse>
      </div>
    </Container>
  );
};

export const OTP = ({
  setIsShowOTP,
  digits,
  setDigits,
  expireIn,
  setExpireIn,
  otpRef,
  setOtpRef,
  handleLogin,
}) => {
  const { callApi, toast, profileState, go } = Context();

  const submit = async () => {
    const otpCode = digits.join("");
    if (digits.length !== otpCode.length) {
      toast("โปรดใส่รหัสให้ครบถ้วน");
    } else {
      handleLogin();
    }
  };

  const CountDown = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  useEffect(() => {
    const countDown = () =>
      setTimeout(() => {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          }
        });
        countDown();
      }, 1000);
    countDown();
    return () => clearTimeout(countDown);
  }, []);

  return (
    <Collapse
      className="flex flex-col justify-center mt-4 p-3 shadow-md items-center bg-white rounded-md w-full max-w-[500px]"
      isOpen={expireIn > 0}
    >
      {/* {expireIn ? ( */}
      <div className="flex flex-col justify-center items-center bg-white rounded-md w-full max-w-[500px]">
        <p className=" text-center font-semibold text-navy ">
          กรุณากรอกรหัสยืนยันของท่าน
        </p>
        <p className=" text-center font-semibold text-navy ">
          รหัสอ้างอิง {otpRef}
        </p>
        <p className=" text-center font-semibold text-navy mb-4">
          หมดอายุภายใน <CountDown second={expireIn} />
        </p>
        <InputBox
          digits={digits}
          setDigits={setDigits}
          action={submit}
          gap="15px"
        />
        <button
          className="w-full bg-navy text-white font-semibold hover:opacity-65 px-3 py-2 rounded-md disabled:bg-slate-200 mt-4"
          disabled={R.isEmpty(digits[5])}
          onClick={submit}
        >
          ยืนยัน
        </button>
        <p
          className="text-red-600 font-semibold mt-3"
          onClick={() => setExpireIn(undefined)}
        >
          ย้อนกลับ
        </p>
      </div>
    </Collapse>
  );
};

export const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 338px;
  height: 92px;

  //ipad size
  @media only screen and (${devices.tablet}) {
    max-width: 130;
    height: 30;
    margin-bottom: 30px;
    &.absolute {
      height: 500px;
      max-width: 300px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /* //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
    margin-bottom: 0;
  } */
`;
export const Absolute = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  left: 0;
  top: 100px;
  z-index: 0;
  @media only screen and (${devices.tablet}) {
    margin: 0;
    top: 0%;
    width: 100%;
  }
  //iphone size
  @media only screen and (${devices.mobile}) {
    width: 130px;
    height: 44px;
  }
`;
