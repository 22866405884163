import React, { Children } from "react";
import themes from "../../themes";
import styled from "styled-components";
import { fontWeight, devices } from "../../constants";
import { Badge as reactstrapBadge } from "reactstrap";

const BadgeStyle = styled(reactstrapBadge)`
  background-color: ${(props) =>
    themes.colors[props.background] || themes.colors.white} !important;
  color: ${(props) => themes.colors[props.color] || themes.colors.white};
  padding: ${(props) => props.padding || "8px 16px"};
  text-wrap: balance;
  white-space: break-spaces;
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
`;

export const Badge = (props) => {
  const { children, className } = props;

  return (
    <BadgeStyle {...props} className={className}>
      {children}
    </BadgeStyle>
  );
};
