import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import style from "../constants/style";
import Context from "../Context";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

import { Button, Wrapper, Container, Typo, Flex } from "../components";

import { LoginWrapper, Absolute, LogoWrapper } from "./Login";

export const RegisterSuccess = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", "UA-213158358-1");
  });
  const gtag = (arg) => {
    window.dataLayer.push(arg);
  };
  return (
    <Warpper>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-213158358-1"
        ></script>
      </Helmet>
      <Success />
    </Warpper>
  );
};

const Warpper = styled.div`
  text-align: center;
  color: ligntgray;
`;
const Success = () => {
  const { go } = Context();
  return (
    <Container className="relative flex justify-center items-start">
      <img
        className="absolute w-full h-full"
        src="/images/bg-hong.svg"
        alt="logo"
      />
      <div className="flex flex-col w-full md:flex-row gap-4 z-10">
        <img src="/images/logodotcom.svg" alt="logo" />

        <div className="flex flex-col justify-center p-2.5 items-center bg-white rounded-md w-full max-w-[500px]">
          <p className=" text-center text-xl font-semibold text-navy my-4">
            ยินดีตอนรับสู่หงษ์ทองออนไลน์
          </p>
          <button
            className="w-full bg-navy text-white font-semibold hover:opacity-65 px-3 py-2 rounded-md disabled:bg-slate-200 mt-4"
            onClick={() => {
              go("/");
              window.location.reload();
            }}
          >
            ซื้อลอตเตอรี่
          </button>
        </div>
      </div>
    </Container>
  );
};
