import { useState } from "react";
import { useParams } from "react-router-dom";
import Context from "../../Context";
import { useEffect } from "react";
import { useToast } from "../../components/ui";
import { ProgressBar, Container, ContentContainer } from "../../components";
import {
  FaTimes,
  FaCheckCircle,
  FaHourglassHalf,
  FaTimesCircle,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { statuses, getThaiDateFormat } from "../../constants";

export const OrderStatus = () => {
  return <GuestOrderStatus />;
};

const GuestOrderStatus = () => {
  const { go, callApi } = Context();
  const { orderId } = useParams();
  const { toast } = useToast();

  const [order, setOrder] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  useEffect(() => {
    if (!localStorage.guestId) {
      go("/");
    }
    getOrder();
  }, []);

  const getOrder = async () => {
    const { status, message, orderData, isCurrentUser } = await callApi(
      "orders-getGuestrOrderStatus",
      {
        orderId,
        guestId: localStorage.guestId,
      }
    );

    if (!status) {
      toast({
        className: "bg-[#c15b78]",
        title: (
          <div className="flex gap-2 items-center">
            <FaTimes /> {message || "ไม่พบคำสั่งซื้อ"}
          </div>
        ),
      });
      go("/");
    }

    setOrder(orderData);
    setIsCurrentUser(isCurrentUser);
  };

  return (
    <div className="w-full mb-3">
      <div className="bg-white w-full">
        <ProgressBar
          status={
            order.status === statuses.APPROVE
              ? 4
              : order.status === "pending_offline"
              ? 2
              : 3
          }
        />
      </div>
      <Container>
        <ContentContainer minHeight="0px" padding="16px">
          <img
            src={"/images/status_approved.svg"}
            alt="icon"
            className="w-full max-w-56 mx-auto mt-2 mb-8"
          />
          <p className="text-center text-navy font-semibold text-xl sm:text-2xl ">
            ชำระเงินสำเร็จ
          </p>
          <p
            className="font-medium
           text-base text-center mt-3"
          >
            ระบบได้ทำการอนุมัติคำขอชำระเงินของท่านเรียบร้อยแล้ว!
          </p>

          {isCurrentUser && (
            <p
              className="font-semibold
           text-sm sm:text-lg text-navy text-center mb-3"
            >
              คุณได้รับคะแนนจำนวน {order.size} คะแนน ที่เบอร์{" "}
              {order.phone.slice(0, 3)}xxx{order.phone.slice(6, 20)}{" "}
              (เป็นสมาชิกหงษ์ทอง)
            </p>
          )}

          <button
            onClick={() => go("/login")}
            className="w-full bg-navy text-white text-lg font-medium py-1.5 rounded-md hover:opacity-70 duration-150"
          >
            เข้าสู่ระบบ
          </button>
        </ContentContainer>
      </Container>
    </div>
  );
};
