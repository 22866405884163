import React, { useEffect, useState } from "react";
import Context from "../Context";
import styled from "styled-components";
import {
  Input,
  Button,
  Wrapper,
  Container,
  Div,
  Typo,
  Flex,
  InputBox,
} from "../components";
import style from "../constants/style";
import * as R from "ramda";
import { FiX } from "react-icons/fi";
import themes from "../themes";
import { LoginWrapper, Absolute, LogoWrapper } from "./Login";

export const RegisterOtp = () => {
  const { callApi, toast, profileState, go } = Context();
  const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  const [expireIn, setExpireIn] = useState(
    profileState?.otp?.expireAt - new Date()
  );

  const random = () => {
    return Math.floor(Math.random() * 60466175)
      .toString(36)
      .toUpperCase()
      .padStart(5, "0");
  };
  const [otpRef, setOtpRef] = useState(profileState?.otp?.ref || "");

  const submit = async () => {
    const otpCode = digits.join("");
    if (digits.length !== otpCode.length) {
      toast("โปรดใส่รหัสให้ครบถ้วน");
    } else {
      const result = await callApi("users-activateUser", {
        otpRef,
        otpCode,
        referNo: localStorage.getItem("referNo"),
      });
      if (result.status) {
        go("/register-success");
      } else {
        result.toastMessage && toast(result.toastMessage);
      }
    }
  };

  const CountDown = ({ second = 0 }) => {
    if (second <= 0) return <span>00:00</span>;
    second = parseInt(second / 1000);
    const pattern =
      String(Math.floor(second / 60)).padStart(2, "0") +
      ":" +
      String(second % 60).padStart(2, "0");
    return <span>{pattern}</span>;
  };

  useEffect(() => {
    const countDown = () =>
      setTimeout(() => {
        setExpireIn((x) => {
          if (x >= 1000) {
            return x - 1000;
          }
        });
        countDown();
      }, 1000);
    countDown();
    return () => clearTimeout(countDown);
  }, []);

  return (
    <Container className="relative flex justify-center items-start">
      <img
        className="absolute w-full h-full"
        src="/images/bg-hong.svg"
        alt="logo"
      />
      <div className="flex flex-col  w-full md:flex-row gap-4 z-10">
        <img src="/images/logodotcom.svg" alt="logo" />

        {expireIn ? (
          <div className="flex flex-col justify-center p-4 items-center bg-white rounded-md w-full max-w-[500px]">
            <p className=" text-center font-semibold text-navy ">
              กรุณากรอกรหัสยืนยันของท่าน
            </p>
            <p className=" text-center font-semibold text-navy ">
              รหัสอ้างอิง {otpRef}
            </p>
            <p className=" text-center font-semibold text-navy mb-4">
              หมดอายุภายใน <CountDown second={expireIn} />
            </p>
            <InputBox
              digits={digits}
              setDigits={setDigits}
              action={submit}
              gap="15px"
            />
            <button
              className="w-full bg-navy text-white font-semibold hover:opacity-65 px-3 py-2 rounded-md disabled:bg-slate-200 mt-4"
              disabled={R.isEmpty(digits[5])}
              onClick={submit}
            >
              ยืนยัน
            </button>
            <p
              className="text-sm mt-3 text-right text-red-600 font-medium cursor-pointer"
              onClick={() => go("/logout")}
            >
              ยกเลิก
            </p>
          </div>
        ) : (
          <div className="flex flex-col justify-center p-4 items-center bg-white rounded-md w-full max-w-[500px]">
            <p className="text-xl font-semibold text-navy mt-2 mb-4">
              กรุณายืนยันหมายเลขโทรศัพท์
            </p>
            <button
              className="w-full bg-navy text-white font-semibold hover:opacity-65 px-3 py-2 rounded-md"
              onClick={async () => {
                const tempOtpRef = random();
                const result = await callApi("users-requestOtp", {
                  otpRef: tempOtpRef,
                });
                setOtpRef(tempOtpRef);
                setExpireIn(result.expireAt - new Date());
                setDigits(["", "", "", "", "", ""]);
              }}
            >
              ขอรหัส OTP
            </button>
            <p
              className="text-sm mt-3 text-right text-red-600 font-medium cursor-pointer"
              onClick={() => go("/logout")}
            >
              ยกเลิก
            </p>
          </div>
        )}
      </div>
    </Container>
    // <Warpper>
    //   <br />
    //   <br />
    //   <ProgressBarNumber status={"2"} texts={["1", "2", "3"]} />
    //   <h2 style={{ color: "darkgoldenrod" }}>ยืนยันการสมัครสมาชิก</h2>
    //   {expireIn && (
    //     <h3 style={{ color: "lightgray" }}>
    //       กรุณากรอกรหัสยืนยันของท่าน
    //       <br />
    //       เลขที่อ้างอิง (<span>{otpRef || "กดขอรหัส"}</span>) <br />
    //       หมดอายุภายใน <CountDown second={expireIn} />
    //     </h3>
    //   )}
    //   <div
    //     style={{
    //       maxWidth: "300px",
    //       margin: "auto",
    //       padding: "20px",
    //     }}
    //   >
    //     {expireIn && (
    //       <div>
    //         <InputBox digits={digits} setDigits={setDigits} action={submit} />
    //       </div>
    //     )}
    //     <Flex direction="column" align="center">
    //       {!(expireIn > 0) && (
    //         <Btn
    //           className="button-style-primary small"
    //           text={otpRef ? "กดเพื่อขอ OTP ใหม่" : " กดเพื่อขอ OTP"}
    //           onClick={async () => {
    //             const tempOtpRef = random();
    //             const result = await callApi("users-requestOtp", {
    //               otpRef: tempOtpRef,
    //             });
    //             setOtpRef(tempOtpRef);
    //             setExpireIn(result.expireAt - new Date());
    //             setDigits(["", "", "", "", "", ""]);
    //           }}
    //           style={{ margin: "auto" }}
    //         />
    //       )}
    //       {expireIn && (
    //         <Btn
    //           className={
    //             R.isEmpty(digits[5])
    //               ? "disabled small"
    //               : "button-style-primary small"
    //           }
    //           text={"ยืนยัน"}
    //           disabled={R.isEmpty(digits[5])}
    //           onClick={submit}
    //           margin="2em 0"
    //         />
    //       )}
    //     </Flex>
    //   </div>
    // </Warpper>
  );
};

const Warpper = styled.div`
  text-align: center;
  color: ligntgray;
`;
