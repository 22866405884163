import React, { useEffect, useState } from "react";
import Context from "../../Context";

import {
  Container,
  Typo,
  Flex,
  ContentContainer,
  Breadcrumb,
} from "../../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { ImCheckboxChecked } from "react-icons/im";

export const Pdpa = () => {
  const { callApi, configs, profileState, go } = Context();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Breadcrumb
        data={[
          { path: "/profile", name: "ข้อกำหนดและเงื่อนไข" },
          { name: "จัดการข้อมูลการให้การยินยอม PDPA" },
        ]}
      />
      <div className="mt-14">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className=" font-semibold text-xl text-center text-white">
            จัดการข้อมูลการให้การยินยอม
          </p>
        </ContentContainer>
        <div className="bg-white p-4 flex flex-col gap-3 rounded-xl mt-4 shadow-md">
          <p className=" text-[#003A9E]">
            ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง บริษัท หงษ์ทอง
            ลอตเตอรี่ออนไลน์ จำกัด กับผู้ซื้อหรือผู้รับบริการ
          </p>
          <p className=" text-[#003A9E]">
            1. “ผู้ซื้อ” หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
            ตลอดจนผู้มีส่วนเกี่ยวข้อง
          </p>
          <p className="text-[#003A9E]">
            2. “บริการ” หมายถึง
            บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
          </p>
          <p className=" text-[#003A9E]">
            3.“ผู้ให้บริการ” หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
          </p>
          <p className=" text-[#003A9E]">
            4. “บริษัทฯ” หมายถึง บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
          </p>
          <p className=" text-[#003A9E]">
            5. “สลากกินแบ่งรัฐบาล” หมายถึง
            สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
          </p>
          <p className=" text-[#003A9E]">
            6. “แพลตฟอร์ม” หมายถึง “https://www.hongthongofficial.com” หรือ “
            https://shop.hongthongofficial.com ” หรือ “แอปพลิเคชันหงษ์ทอง.COM”
            ซึ่งเป็นบริษัทฯ ผู้ให้บริการจำหน่าย รับจัดเก็บ รับฝาก
            รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น ๆ
            ตามวัตถุประสงค์ของบริษัทฯ
          </p>
          <p className=" text-[#003A9E]">
            7. ผู้ซื้อยินยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
            ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal Data
            Protection Act B.E. 2562 (2019)) เพื่อมีไว้ใช้และเปิดเผย
            ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
            เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
            เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี, เลขบัญชีธนาคาร,
            เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
            ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
            จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่, อีเมล์,เลขโทรศัพท์ /
            ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP address, MAC address, Cookie ID
            / ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์, โฉนดที่ดิน /
            ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
            วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
            ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
            ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
            ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
            แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
            /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
            / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง ๆ ของบุคคล เช่น
            log file
            /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
            ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
            ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
            ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ, ประวัติอาชญากรรม,
            ข้อมูลสุขภาพ ความพิการ หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
            ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ (Biometric) เช่น
            รูปภาพใบหน้า, ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
            ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
            เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
          </p>

          <div className="flex items-start mt-3 font-semibold gap-3 text-green-600">
            <div className="w-8 h-8 mt-1">
              <ImCheckboxChecked className="" />
            </div>
            <p>
              ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
              และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
              การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
              ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
              ตามที่ผู้ให้บริการเห็นสมควร
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
