import React, { useEffect, useState } from "react";
import Context from "../../Context";

import {
  Container,
  Typo,
  Flex,
  ContentContainer,
  Breadcrumb,
} from "../../components";
import { FaRegCalendarPlus } from "react-icons/fa6";
import {
  useToast,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { ImCheckboxChecked } from "react-icons/im";
import {
  bankList,
  provinces,
  getThaiDateFormat,
  validate,
} from "../../constants";

// import { th } from "date-fns/locale";

import th from "antd/es/date-picker/locale/th_TH";
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { DatePicker } from "antd";

dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

const buddhistLocale = {
  ...th,
  lang: {
    ...th.lang,
    fieldDateFormat: "DD-MM-BBBB",
    fieldDateTimeFormat: "DD-MM-BBBB HH:mm:ss",
    yearFormat: "BBBB",
    cellYearFormat: "BBBB",
  },
};

export const UserInfo = () => {
  const { toast } = useToast();
  const { callApi, configs, profileState, go } = Context();
  const [profile, setProfile] = useState(profileState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container className="relative">
      <Breadcrumb
        data={[
          { path: "/profile", name: "ข้อมูลสมาชิก" },
          { name: "รายละเอียดข้อมูลสมาชิก" },
        ]}
      />
      <div className="mt-8">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className=" font-semibold text-xl text-center text-white">
            รายละเอียดข้อมูลสมาชิก
          </p>
        </ContentContainer>
        <div className="bg-white p-4 rounded-lg mt-3 shadow-md">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div>
              <p className="text-muted text-sm">ชื่อ</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100  disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.firstName}
                disabled={profileState.firstName}
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <p className="text-muted text-sm">นามสกุล</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100  disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.lastName}
                disabled={profileState.lastName}
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
              />
            </div>
            <div>
              <p className="text-muted text-sm">วันเกิด</p>
              {profileState?.dob ? (
                <input
                  type="text"
                  className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100  disabled:text-slate-600 disabled:cursor-not-allowed"
                  value={new Date(profileState.dob).toLocaleDateString(
                    "th-TH",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                  disabled
                />
              ) : (
                <DatePicker
                  className="w-full h-12 border-2 border-gray-300 font-semibold rounded-lg p-2 disabled:bg-slate-100  disabled:text-slate-600 disabled:cursor-not-allowed"
                  locale={buddhistLocale}
                  onChange={(en, th) => {
                    const selectedDate =
                      th.split("-")[2] -
                      543 +
                      "-" +
                      th.split("-")[1] +
                      "-" +
                      th.split("-")[0];

                    setProfile((prevState) => ({
                      ...prevState,
                      dob: selectedDate,
                    }));
                  }}
                />
              )}
            </div>
            <div>
              <p className="text-muted text-sm">สัญชาติ</p>
              <select
                value={profile.nationality}
                disabled={profileState.nationality}
                className="border-2 border-gray-300 h-[44px] font-semibold rounded-lg p-2 w-full disabled:bg-slate-100 disabled:text-slate-600 disabled:cursor-not-allowed"
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    nationality: e.target.value,
                  }))
                }
              >
                <option value=""></option>
                <option value="TH">ไทย</option>
                <option value="LAO">ลาว</option>
              </select>
            </div>
            <div>
              <p className="text-muted text-sm">เบอร์โทรศัพท์</p>
              <input
                type="number"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100 disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.phone}
                disabled={true}
                // onChange={(e) => {
                //   setProfile((prevState) => ({
                //     ...prevState,
                //     phone: e.target.value,
                //   }));
                // }}
              />
            </div>
            <div>
              <p className="text-muted text-sm">อีเมล์</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full"
                value={profile.email}
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className=" font-semibold text-xl text-center text-white">
            บัญชีธนาคาร
          </p>
        </ContentContainer>
        <div className="bg-white p-4 rounded-lg mt-3 shadow-md">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div>
              <p className="text-muted text-sm">ชื่อบัญชี</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100 disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.bank?.accName}
                disabled={profileState.bank?.accName}
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    bank: { ...prevState.bank, accName: e.target.value },
                  }))
                }
              />
            </div>

            <div>
              <p className="text-muted text-sm">ธนาคาร</p>
              <select
                className="border-2 border-gray-300 h-[44px] font-semibold rounded-lg p-2 w-full disabled:bg-slate-100 disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.bank?.name}
                disabled={profileState.bank?.name}
                onChange={(e) => {
                  setProfile((prevState) => ({
                    ...prevState,
                    bank: { ...prevState.bank, name: e.target.value },
                  }));
                }}
              >
                <option value=""></option>
                {bankList.map((bank) => (
                  <option key={bank.bankName} value={bank.bankName}>
                    {bank.bankName}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p className="text-muted text-sm">เลขที่บัญชี</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full disabled:bg-slate-100 disabled:text-slate-600 disabled:cursor-not-allowed"
                value={profile.bank?.accNo}
                disabled={profileState.bank?.accNo}
                onChange={(e) => {
                  if (validate.digitOnly.test(e.target.value))
                    setProfile((prevState) => ({
                      ...prevState,
                      bank: { ...prevState.bank, accNo: e.target.value },
                    }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className=" font-semibold text-xl text-center text-white">
            ที่อยู่ของฉัน
          </p>
        </ContentContainer>
        <div className="bg-white p-4 rounded-lg mt-3 shadow-md">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            <div>
              <p className="text-muted text-sm">ที่อยู่</p>
              <input
                type="text"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full "
                value={profile.address.addr}
                onChange={(e) =>
                  setProfile((prevState) => ({
                    ...prevState,
                    address: { ...prevState.address, addr: e.target.value },
                  }))
                }
              />
            </div>

            <div>
              <p className="text-muted text-sm">จังหวัด</p>
              <select
                className="border-2 border-gray-300 h-[44px] font-semibold rounded-lg p-2 w-full"
                value={profile.address.province}
                onChange={(e) => {
                  setProfile((prevState) => ({
                    ...prevState,
                    address: {
                      ...prevState.address,
                      province: e.target.value,
                    },
                  }));
                }}
              >
                <option value=""></option>
                {provinces.map((province) => (
                  <option key={province} value={province}>
                    {province}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <p className="text-muted text-sm">รหัสไปรษณีย์</p>
              <input
                type="number"
                className="border-2 border-gray-300 font-semibold rounded-lg p-2 w-full"
                value={profile.address.zipcode}
                onChange={(e) => {
                  setProfile((prevState) => ({
                    ...prevState,
                    address: {
                      ...prevState.address,
                      zipcode: e.target.value,
                    },
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <button
        className="sticky bottom-[120px] md:bottom-2 mt-3 w-full bg-[rgb(59,176,101)] px-3 py-2 text-xl rounded-lg font-semibold text-white"
        onClick={async () => {
          const { status } = await callApi("users-editSelf", {
            fields: [
              { key: "firstName", value: profile.firstName },
              { key: "lastName", value: profile.lastName },
              { key: "nationality", value: profile.nationality },
              { key: "dob", value: profile.dob || false },
              { key: "email", value: profile.email },
              { key: "bank", value: profile.bank },
              { key: "address", value: profile.address },
            ],
            id: profileState.userId,
          });

          if (status) {
            toast({
              className: "bg-[rgb(59,176,101)]",
              title: `อัปเดตข้อมูลสำเร็จ`,
            });

            go("/profile");
            window.location.reload();
          } else {
            toast({
              className: "bg-[#c15b78]",
              title: `อัปเดตข้อมูลไม่สำเร็จ`,
            });
          }
        }}
      >
        บันทึก
      </button>
    </Container>
  );
};
