import React, { useEffect, useState } from "react";
import Context from "../../Context";

import {
  Container,
  Typo,
  Flex,
  ContentContainer,
  Breadcrumb,
} from "../../components";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { ImCheckboxChecked } from "react-icons/im";

export const Policy = () => {
  const { callApi, configs, profileState, go } = Context();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Breadcrumb
        data={[
          { path: "/profile", name: "ข้อกำหนดและเงื่อนไข" },
          { name: "ข้อตกลงและเงื่อนไขของแอปฯหงษ์ทอง" },
        ]}
      />
      <div className="mt-14">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className=" font-semibold text-xl text-center text-white">
            ข้อตกลงและเงื่อนไขของแอปฯหงษ์ทอง
          </p>
        </ContentContainer>

        <div className="bg-white p-4 flex flex-col gap-3 rounded-xl mt-4 shadow-md">
          <p className=" text-[#003A9E]">
            ฉัน ในฐานะผู้ซื้อลอตเตอรี่ ตกลงและทราบดีว่า
            <span className="font-bold">หงษ์ทอง</span>
            ไม่จำหน่ายลอตเตอรี่ในสถานศึกษา และให้กับบุคคลที่อายุต่ำกว่า 20 ปี
            ทาง<span className="font-bold">หงษ์ทอง</span>{" "}
            ขอสงวนสิทธิ์ในการดำเนินคดีตามกฎหมายสูงสุด ทั้งทางแพ่งและอาญา
            กับผู้ที่มาซื้อลอตเตอรี่กับ
            <span className="font-bold">หงษ์ทอง</span>
            โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์ หรือซื้อในสถานศึกษา
          </p>
          <p className=" text-[#003A9E]">
            ฉันตกลงยินยอม และยอมรับเงื่อนไขต่างๆ ที่ทาง
            <span className="font-bold">หงษ์ทอง</span>กำหนดขึ้น
            ทั้งในปัจจุบันและหรือในอนาคต เพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่
            ว่าไม่ต่ำกว่า 20 ปีบริบูรณ์
          </p>
          <p className="text-[#003A9E]">
            <span className="font-bold">หงษ์ทอง</span>
            ขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อ
            และฉันตกลงยอมจ่ายค่าปรับให้กับทาง
            <span className="font-bold">หงษ์ทอง</span> เป็นเงิน 10,000
            (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ ในกรณีที่
            <span className="font-bold">หงษ์ทอง</span>ตรวจพบว่า
            ฉันซื้อลอตเตอรี่ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20 ปีบริบูรณ์
          </p>
          <p className=" text-[#003A9E]">
            ฉันตกลงและทราบดีว่า ทาง
            <span className="font-bold">หงษ์ทอง</span>
            ขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูกรางวัล
            และจะไม่คืนเงินค่าซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่ ในกรณีที่
            <span className="font-bold">หงษ์ทอง</span>ตรวจพบว่า
            ผู้ซื้อมีอายุต่ำกว่า 20 ปีบริบูรณ์{" "}
            <span className="text-red-600">**</span>
          </p>
          <div className="flex items-start mt-3 font-semibold gap-2 text-green-600">
            <div className="w-8 h-8 mt-1">
              <ImCheckboxChecked />
            </div>

            <p>
              ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
              ปัจจุบันฉันมีอายุครบ 20 ปีบริบูรณ์
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
