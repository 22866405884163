import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBase } from "./ModalBase";
import Context from "../Context";
import { Collapse, Modal, Spinner } from "reactstrap";

import { validate } from "../constants";

import { useToast } from "../components/ui";
export const ModalRequiredInformation = ({
  isOpen,
  setShowModalRequiredInformation,
}) => {
  const { hideModal, callApi, profileState, go, updateProfileState } =
    Context();
  const { toast } = useToast();
  const [userRequiredData, setUserRequiredData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isFinished, setIsFinished] = useState(false);

  // const [isShowOtp, setIsShowOtp] = useState(false);
  // const [expireIn, setExpireIn] = useState(0);
  // const [otpRef, setOtpRef] = useState("");
  // const [digits, setDigits] = useState(["", "", "", "", "", ""]);
  // const [isShowPass, setIsShowPass] = useState(false);
  useEffect(() => {
    if (profileState) {
      setUserRequiredData({
        firstName: profileState.firstName || "",
        lastName: profileState.lastName || "",
        phone: profileState.phone || "",
      });
    }
  }, [profileState]);

  // useEffect(() => {
  //   const countDown = () =>
  //     setTimeout(() => {
  //       setExpireIn((x) => {
  //         if (x >= 1000) {
  //           return x - 1000;
  //         }
  //       });
  //       countDown();
  //     }, 1000);
  //   countDown();
  //   return () => clearTimeout(countDown);
  // }, []);

  const handleChange = (e) => {
    setUserRequiredData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // const handleSubmit = async () => {
  //   setIsSubmitting(!isSubmitting);
  //   const tempOtpRef = randomLetters(5);
  //   const { result } = await callApi(
  //     "users-requestChangePhoneNumberOtp",
  //     {
  //       // otpRef: tempOtpRef,
  //       phone: userRequiredData.phone,
  //     },
  //     { notShowLoading: true }
  //   );
  //   if (result.expireAt) {
  //     setIsSubmitting(false);
  //     setOtpRef(tempOtpRef);
  //     setExpireIn(result.expireAt - new Date());
  //     setIsShowOtp(!isShowOtp);
  //     setDigits(["", "", "", "", "", ""]);
  //   } else {
  //     setIsSubmitting(!isSubmitting);
  //   }
  // };

  // const CountDown = ({ second = 0 }) => {
  //   if (second <= 0) return <span>00:00</span>;
  //   second = parseInt(second / 1000);
  //   const pattern =
  //     String(Math.floor(second / 60)).padStart(2, "0") +
  //     ":" +
  //     String(second % 60).padStart(2, "0");
  //   return <span>{pattern}</span>;
  // };

  const submit = async () => {
    setIsSubmitting(true);

    if (
      userRequiredData.phone &&
      userRequiredData.firstName &&
      userRequiredData.lastName
    ) {
      const { status } = await callApi("users-editSelf", {
        fields: [
          { key: "firstName", value: userRequiredData.firstName },
          { key: "lastName", value: userRequiredData.lastName },
          { key: "phone", value: userRequiredData.phone },
        ],
        id: profileState.userId,
      });

      if (status) {
        toast({
          className: "bg-[rgb(59,176,101)]",
          title: `อัปเดตข้อมูลสำเร็จ`,
        });

        setShowModalRequiredInformation({ isShow: false });
      } else {
        toast({
          className: "bg-[#c15b78]",
          title: `อัปเดตข้อมูลไม่สำเร็จ`,
        });
        setIsSubmitting(false);
      }
    } else {
      toast("โปรดใส่ข้อมูลให้ครบถ้วน");
    }
  };

  return (
    <Modal centered isOpen={isOpen}>
      <div className=" p-3 items-center shadow-md bg-white rounded-md w-full max-w-[500px]">
        <p className="text-center text-navy text-xl font-semibold">
          กรุณาใส่ข้อมูลสำคัญให้ครบถ้วน
        </p>

        <p className={`text-sm font-semibold ${"text-navy"} duration-150 mt-3`}>
          ชื่อ
        </p>
        <input
          className={` ${"border-3 border-navy placeholder:text-0 placeholder:text-sm placeholder:font-normal"} 
             font-semibold rounded-md p-2 w-full text-navy duration-150`}
          type="text"
          name="firstName"
          onChange={(e) => handleChange(e)}
          value={userRequiredData.firstName}
        />

        <p className={`text-sm font-semibold ${"text-navy"} duration-150 mt-2`}>
          นามสกุล
        </p>
        <input
          className={` ${"border-3 border-navy placeholder:text-0 placeholder:text-sm placeholder:font-normal"} 
             font-semibold rounded-md p-2 w-full text-navy duration-150`}
          type="text"
          name="lastName"
          onChange={(e) => handleChange(e)}
          value={userRequiredData.lastName}
        />

        <p className={`text-sm font-semibold ${"text-navy"} duration-150 mt-2`}>
          เบอร์โทรศัพท์
        </p>
        <input
          className={` ${"border-3 border-navy placeholder:text-0 placeholder:text-sm placeholder:font-normal disabled:opacity-45"} 
             font-semibold rounded-md p-2 w-full text-navy duration-150`}
          type="text"
          name="phone"
          onChange={(e) =>
            validate.digitOnly.test(e.target.value) && handleChange(e)
          }
          disabled={profileState.phone}
          value={userRequiredData.phone}
        />

        <button
          className="w-full h-[46px] px-3 bg-[rgb(59,176,101)] text-white font-semibold rounded-md mt-3 disabled:opacity-45 duration-150"
          disabled={
            isSubmitting || Object.values(userRequiredData).some((e) => !e)
          }
          onClick={() => submit()}
        >
          {isSubmitting ? <Spinner className="text-white h-4 w-4" /> : "ยืนยัน"}
        </button>
      </div>
    </Modal>
  );
};
