import React, { useEffect, useState, useRef } from "react";
import {
  Banner,
  SearchBox,
  ShopList,
  Button,
  Flex,
  Container,
  Typo,
  Div,
} from "../components";
import Context from "../Context";
import styled from "styled-components";
import style from "../constants/style";
import { devices } from "../constants";
import themes from "../themes";
import { useParams } from "react-router-dom";
import { Loading } from "../components";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Home = () => {
  const { callApi, go, profileState } = Context();
  const bottomRef = useRef(null);
  const [blocks, setBlocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [firstNumber, setFirstNumber] = useState(-1);
  const [lastNumber, setLastNumber] = useState(-1);
  const [loopMark, setLoopMark] = useState(false);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const { digitsPath, filterPath } = useParams();
  const [ts, setTs] = useState(Number(new Date()));
  const [subScribed, setSubScribed] = useState(false);
  const NO_MORE = "No more Lottery";
  const filterStatus = {
    all: "all",
    random: "random",
    one: "one",
    two: "two",
    three: "three",
    four: "four",
    five: "five",
    big: "big",
    prettyFronts: "prettyFronts",
    prettyFront: "prettyFront",
    specialNumber: "specialNumber",
    specialEvent: "specialEvent",
    magicNumber: "magicNumber",
    labubuNumber: "labubuNumber",
  };
  const redirecPath = ["/", "/shop"];
  useEffect(() => {
    if (redirecPath.includes(window.location.pathname)) go("/search/all");
  }, []);

  const refresh = async () => {
    setBlocks([]);
    await getMoreLotteries({ notShowLoading: true }, -1, -1, true);
    setLastNumber(NO_MORE);
  };

  const getNumberType = () => {
    if (/^x{4}\d{2}$/.test(digitsPath)) {
      return {
        type: "lastTwo",
        number: digitsPath.slice(-2),
      };
    } else if (/^x{3}\d{3}$/.test(digitsPath)) {
      return {
        type: "lastThree",
        number: digitsPath.slice(-3),
      };
    } else if (/^\d{3}x{3}$/.test(digitsPath)) {
      return {
        type: "firstThree",
        number: digitsPath.slice(0, 3),
      };
    } else if (/^\d{6}$/.test(digitsPath)) {
      return {
        type: "number",
        number: digitsPath,
      };
    } else {
      return {
        type: "unknow",
        number: "",
      };
    }
  };
  const getMoreLotteries = async (option = {}, a, z, forceLoopMark) => {
    if ((z || lastNumber) === NO_MORE) return;
    setIsLoading(true);

    const digits = digitsPath
      ? digitsPath.split("").reduce((acc, x, i) => {
          if (x !== "x") {
            acc[`d${i + 1}`] = x;
          }
          return acc;
        }, {})
      : {};

    let size;
    let limit = 60;
    switch (filterPath) {
      case filterStatus.random:
        size = 0;
        limit = 1;
        break;
      case filterStatus.all:
        size = 0;
        break;
      case filterStatus.one:
        size = 1;
        break;
      case filterStatus.two:
        size = 2;
        break;
      case filterStatus.three:
        size = 3;
        break;
      case filterStatus.four:
        size = 4;
        break;
      case filterStatus.five:
        size = 5;
        break;
      case filterStatus.big:
        size = [
          6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
          24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
        ];
        break;

      default:
        size = 0;
        break;
    }

    const blockResults = await callApi(
      "blocks-searchBlocks",
      {
        random: Math.floor(Math.random() * 1000000),
        digits,
        size,
        limit,
        isPrettyFront: filterPath === "prettyFronts",
        specialEvent: filterPath === "specialEvent",
        // isGuest: !localStorage.getItem("accessToken"),
        isMagicNumber: filterPath === "magicNumber",
        isLabubuNumber: filterPath === "labubuNumber",
        z: z || lastNumber,
        a: a || firstNumber,
        loopMark: !forceLoopMark && loopMark,
      },
      option
    );
    const blockResultsLength = blockResults.length;
    if (blockResultsLength) {
      if (forceLoopMark || !loopMark) {
        if (firstNumber < 0) {
          setFirstNumber(blockResults[0].i);
          setLoopMark(
            blockResults.some((block) => block.i < blockResults[0].i)
          );
        } else {
          setLoopMark(blockResults.some((block) => block.i < firstNumber));
        }
      }
      setLastNumber(blockResults[blockResultsLength - 1].i);
    } else {
      setLastNumber(NO_MORE);
    }
    if (filterStatus.random === filterPath) {
      setLastNumber(NO_MORE);
    }
    setBlocks((oldDesign) => [...oldDesign, ...blockResults]);
    setIsLoading(false);
  };
  // useEffect(() => {
  //   const onScroll = (e) => {
  //     if (!bottomRef.current) return;
  //     const isBottom =
  //       parseInt(bottomRef.current.getClientRects()[0].bottom) <
  //       parseInt(window.innerHeight + 10);
  //     if (isBottom && !isLoading) {
  //       setTs(Number(new Date()));
  //     }
  //   };
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  //   // eslint-disable-next-line
  // }, []);
  useEffect(() => {
    (async () => {
      if (!isLoading && isFirstLoaded) {
        await getMoreLotteries({ notShowLoading: true });
      }
    })();
    // eslint-disable-next-line
  }, [ts]);

  useEffect(() => {
    (async () => {
      setLoopMark(false);
      setBlocks([]);
      await getMoreLotteries({ notShowLoading: true }, -1, -1, true);
      setIsFirstLoaded(true);
    })();
    // eslint-disable-next-line
  }, [digitsPath, filterPath]);
  useEffect(() => {
    const numberType = getNumberType(digitsPath);

    if (
      blocks.length === 0 &&
      !isLoading &&
      isFirstLoaded &&
      profileState.lineId &&
      ["lastTwo", "lastThree", "firstThree", "number"].includes(numberType.type)
    ) {
      callApi("blocks-checkSubscription", numberType).then(({ status }) => {
        setSubScribed(status);
      });
    }
  }, [blocks]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", "UA-213158358-1");
  });
  const gtag = (arg) => {
    window.dataLayer.push(arg);
  };
  return (
    <div
      style={{
        position: "relative",
        textAlign: "center",
        color: "white",
      }}
    >
      <ToastContainer />
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-213158358-1"
        ></script>
      </Helmet>
      <Container margin="0">
        <Banner />

        <SearchBox
          digitsPath={digitsPath}
          filterPath={filterPath}
          filterStatus={filterStatus}
          refresh={refresh}
        />
        <p className="font-bold text-gray-700 text-xl py-8 text-left">
          ผลการค้นหา
        </p>
      </Container>

      {blocks.length ? (
        <>
          <ShopList blocks={blocks} />
          <Flex justify="center" margin="5vh 0">
            <Button
              color="navy"
              background="yellow"
              hoverBg="white"
              hoverColor="yellow"
              hoverBorder="yellow"
              onClick={() => {
                setTs(Number(new Date()));
              }}
            >
              ดูเพิ่มเติม
            </Button>
          </Flex>
        </>
      ) : isLoading ? (
        <div style={{ textAlign: "center" }}>
          <Loading>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </Loading>
        </div>
      ) : (
        <Flex
          direction="column"
          minHeight="25vh"
          margin="0 0 50px 0"
          gap="25px"
        >
          <Typo.H6 color="navy" textAlign="center">
            ไม่พบสิ่งที่ท่านค้นหา
          </Typo.H6>
          {["lastTwo", "lastThree", "firstThree", "number"].includes(
            getNumberType(digitsPath).type
          ) &&
            (subScribed ? (
              <Typo.H6 color="navy">
                ระบบจะแจ้งเตือนท่านเมื่อมีเลขสองตัวท้ายนี้เข้าระบบ
              </Typo.H6>
            ) : (
              <Flex justify="center">
                {/* <Button
                  onClick={async () => {
                    await callApi(
                      "blocks-subscribeBlocks",
                      getNumberType(digitsPath)
                    ).then((result) => result.status && setSubScribed(true));
                  }}
                  background="yellow"
                >
                  แจ้งเตือนเมื่อเลขเข้าระบบ (2 ตัวท้าย)
                </Button> */}
                {/* <Button onClick={() => go("/coupon")} background="yellow">
                  จองสลาก
                </Button> */}
                {/* <Btn
                  className="button-style-primary small"
                  onClick={async () => {
                    await callApi(
                      "blocks-subscribeBlocks",
                      getNumberType(digitsPath)
                    );
                    setSubScribed(true);
                  }}
                  text="รับแจ้งเตือนเมื่อเลขเข้าระบบ"
                ></Btn> */}
              </Flex>
            ))}
          <Flex justify="center">
            <Button
              maxWidth="500px"
              onClick={() => go("/coupon/reserve_coupon")}
              background="yellow"
            >
              จองสิทธิ์เลขท้าย
            </Button>
          </Flex>
        </Flex>
      )}

      <div ref={bottomRef} style={{ position: "absolute", bottom: 0 }} />
    </div>
  );
};

const CountDown = ({ second = 0 }) => {
  second = isNaN(second) ? 0 : second;
  const pattern =
    String(Math.floor(second / 60)).padStart(2, "0") +
    ":" +
    String(second % 60).padStart(2, "0");
  return (
    <span
      style={{
        backgroundColor: "#F1C40E",
        padding: "0 10px",
        color: style.colors.darkBlue,
        borderRadius: "10px",
        marginTop: "-5px",
      }}
    >
      {pattern}
    </span>
  );
};

const ContentContainer = styled.div`
  background-color: ${themes.colors.white};
  width: 100%;
  min-height: 300px;
  margin-top: 25px;
  border-radius: 20px;
  padding: 32px 55px 32px 55px;

  //iphone size
  @media only screen and (${devices.mobile}) {
    padding: 24px 20px 32px 20px;
  }
`;

const CircleWarp = styled.div`
  margin-bottom: -50px;
  margin-left: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  background-color: rgb(20, 54, 85);
`;
const Circle = styled.div`
  background-image: url("/images/logo_single.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;
const H4 = styled.h4`
  margin: 5px 10px;
  font-weight: 400;
`;
const H5 = styled.h5`
  margin: 5px 10px;
  font-weight: 400;
`;

const Dot = styled.div`
  background: green;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white !important;
`;
// margin: -10px 0px;
// const Footer = styled.div`
//         color: ${style.colors.brightGoldText};
//         position: fixed;
//         text-align: center;
//         bottom: 0;
//         height: 81.5px;
//         width: 100%;
//         background-color: ${style.colors.darkBlue};
//         justify-content: center;
//         align-items: flex-end;
//         display: flex;
//         z-index: 9998;
//         `
// const FooterImage = styled.img`
//         width: 32px;
//         height: 32px;
//         `
// const FooterButton = styled(Link)`
//         cursor: pointer;
//         flex: 1;
//         display: inline-block;
//         max-width: 300px;
//         padding: 12px 0px;
//         color: #F1C40E;
//         `
// // ${style.css.shadow}
// const FooterSide = styled(FooterButton)`
//         z-index: 9997;
//         `
// const FooterCart = styled(FooterButton)`
//   max-width: 200px;
//   position: relative;
//   border-radius: 20px;
//   padding-top: 20px;
//   background-color: ${style.colors.darkBlue};
//   z-index: 9998;
// `
