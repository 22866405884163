import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import style from "../../constants/style";
import Context from "../../Context";
import { provinces, bankNames } from "../../constants";
import constant from "../../constants";
import {
  TextBody,
  WhiteHolder,
  FlexHolder,
  Flex,
  Label,
  Typo,
  Container,
  ContentContainer,
  Button,
  Grid,
} from "../../components";
import { LineButton } from "../../components/Btn";
import themes from "../../themes";
import { FiEdit, FiSave, FiCopy } from "react-icons/fi";
import { FaQuestionCircle } from "react-icons/fa";
import { isEmpty, isNil } from "ramda";
import {
  Collapse,
  Modal,
  ModalBody,
  FormGroup,
  Input as ReactstrapInput,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { LuWallet } from "react-icons/lu";
import { TbCash } from "react-icons/tb";
import { RiCoupon2Line } from "react-icons/ri";
import { BsPostcard } from "react-icons/bs";
import { useToast } from "../../components/ui";
import { MdOutlinePolicy } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { ImEyeBlocked } from "react-icons/im";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { PiCards } from "react-icons/pi";

import { PiBankBold } from "react-icons/pi";
import { TbGps } from "react-icons/tb";
import { FaSquarePhone } from "react-icons/fa6";
import { PiCardsThreeBold } from "react-icons/pi";

import { FaFacebookSquare } from "react-icons/fa";
import { FaLine } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { PiPhoneCallFill } from "react-icons/pi";
import { MdOutlineHandshake } from "react-icons/md";

import { statuses } from "../../constants";
export const Profile = () => {
  const { toast } = useToast();
  const { callApi, profileState, setProfileState, go, configs } = Context();
  const [profileData, setProfileData] = useState(profileState);
  const [lottoAmount, setLottoAmount] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const [isEditName, setEditName] = useState(false);
  const [displayName, setDisplayName] = useState("");
  useEffect(() => {
    (async () => {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        localStorage.removeItem("accessToken");
        const lineLoginResult = await callApi("users/lineLogin", { code });

        if (lineLoginResult.data.accessToken) {
          localStorage.setItem("accessToken", lineLoginResult.data.accessToken);
          setProfileState(lineLoginResult.data.user);
          if (localStorage.getItem("redirect")) {
            const redirect = localStorage.getItem("redirect");
            localStorage.removeItem("redirect");
            go(redirect);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    const { data } = await callApi(
      "users-getUserInfo",
      {},
      { notShowLoading: true }
    );
    const { orderData } = await callApi(
      "orders-getUserOrders",
      {},
      { notShowLoading: true }
    );
    const myCoupon = await callApi(
      "coupons-getTransactionByUserId",
      {},
      { notShowLoading: true }
    );

    const availableMyCoupon = myCoupon.data?.filter(
      (coup) =>
        coup.status === statuses.APPROVE && coup.roundDate === configs.roundDate
    );
    const lottoAmount = await orderData.reduce((acc, order) => {
      if (order.status === "approved") {
        acc += Number([...new Set(order.lotteryIds)].length);
      }

      return acc;
    }, 0);

    setCouponAmount(availableMyCoupon.length);
    setLottoAmount(lottoAmount);
    setProfileState(data);
    setProfileData(data);
  };

  const getLink = ({ userNo, userId }) => {
    const link = constant.webConfigs.customerUrl + "HT/" + userId;
    const linkAffiliate = constant.webConfigs.officialUrl + userNo;
    return [
      {
        value: link,
        key: "userNo",
        type: "input",
        outSideGrid: true,
        handleClick: ({ ref }) => {
          const elt = ref.current;
          elt.focus();
          elt.select();
          elt.setSelectionRange(0, 99999);
          window.navigator.clipboard.writeText(link);
          elt.select();
        },
      },
      {
        value: linkAffiliate,
        key: "userNo",
        type: "input",
        outSideGrid: true,
        handleClick: ({ ref }) => {
          const elt = ref.current;
          elt.focus();
          elt.select();
          elt.setSelectionRange(0, 99999);
          window.navigator.clipboard.writeText(linkAffiliate);
          elt.select();
        },
      },
    ];
  };
  const resizeImage = (file) =>
    new Promise((resolve, reject) => {
      if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type))
        reject(false);
      const maxW = 600; //กำหนดความกว้าง
      const maxH = 1200; //กำหนดความสูง
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = document.createElement("img");
      img.onload = function () {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min(maxW / iw, maxH / ih);
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        context.drawImage(img, 0, 0, iwScaled, ihScaled);
        resolve(canvas.toDataURL("image/jpeg", 1)); //0.5 คือ คุณภาพของรูป ที่ Resize
      };
      img.src = URL.createObjectURL(file);
    });

  const [link, setLink] = useState(getLink(profileState));

  const save = async (inputs) => {
    const { status } = await callApi("users-editSelf", {
      fields: inputs || [],
      id: profileState.userId,
    });

    if (status) {
      toast({
        className: "bg-[rgb(59,176,101)]",
        title: `อัปเดตข้อมูลสำเร็จ`,
      });
      getUserInfo();
    } else {
      toast({
        className: "bg-[#c15b78]",
        title: `อัปเดตข้อมูลไม่สำเร็จ`,
      });
    }
  };

  return (
    <Flex direction="column" justify="flex-start" align="center" width="100%">
      <Container margin="0 0 25px 0">
        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="60px 16px 16px 16px"
          paddingTablet="60px 16px 16px 16px"
          paddingMobile="60px 8px 16px 8px"
          margin="80px 0 0 0"
        >
          <label htmlFor="files-slips">
            <Avatar
              src={
                profileData.profileImage
                  ? profileData.profileImage
                  : "/images/logo_single.png"
              }
              alt="profile_logo"
              htmlFor="files-slips"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                  backgroundColor: themes.colors.white,
                  color: themes.colors.navy,
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
                htmlFor="files-slips"
              >
                <FiEdit />
              </div>
            </Avatar>
          </label>

          <input
            style={{
              marginBottom: "15px",
              visibility: "hidden",
              position: "absolute",
              display: "none",
            }}
            id="files-slips"
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              const base64 = await resizeImage(file);
              if (base64) {
                await callApi("users-updateProfileImage", { base64 });
                window.location.reload();
              } else {
                alert("อัพโหลด ได้เฉพาะ ไฟล์รูปภาพ นามสกุล jpg หรือ png");
              }
            }}
          />
          <div
            className="flex flex-col justify-center items-center hover:cursor-pointer"
            onClick={() => setEditName(true)}
          >
            {isEditName ? (
              <div className="flex gap-2 justify-center">
                <input
                  className="rounded-md px-2 bg-transparent border border-white w-52 text-2xl text-white font-semibold text-center"
                  type="text"
                  value={profileData.displayName || ""}
                  onChange={(e) =>
                    setProfileData((prevState) => ({
                      ...prevState,
                      displayName: e.target.value,
                    }))
                  }
                  onBlur={async (e) => {
                    await save([{ key: "displayName", value: e.target.value }]);
                    setEditName(false);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === "Enter") {
                      await save([
                        { key: "displayName", value: e.target.value },
                      ]);
                      setEditName(false);
                    }
                  }}
                />
                <button
                  onClick={async () => {
                    await save([
                      { key: "displayName", value: profileData.displayName },
                    ]);
                    setEditName(false);
                  }}
                  className="py-1 px-2 bg-gold rounded-md  font-semibold"
                >
                  บันทึก
                </button>
              </div>
            ) : (
              <div className="flex gap-2 justify-center items-center ">
                <p className="text-center text-white text-2xl font-semibold ">
                  {profileData.displayName ||
                    profileData.firstName + " " + profileData.lastName}
                </p>
                <FiEdit className="text-gold" />
              </div>
            )}
            <p className="text-center text-white font-semibold mt-1">
              เลขสมาชิก: HT{profileData.userId || "xxxxxx"}
            </p>
          </div>
        </ContentContainer>
        <div className="grid grid-cols-2 md:grid-cols-4 mt-3 mb-3 gap-3">
          <div className="bg-white hover:cursor-pointer  hover:scale-105 duration-150 shadow-sm rounded-[8px] py-3 px-3 ">
            <div className="flex gap-2 items-center">
              <LuWallet className="h-5 w-5" />
              <p className="font-semibold">คะแนนสะสม</p>
            </div>
            <div className="flex mt-2 gap-2 items-center">
              <p className="flex font-semibold text-xl flex-grow text-blue-600">
                {profileData.buyPoint || 0}
              </p>
              <p className="font-semibold">คะแนน</p>
            </div>
          </div>

          <div
            className="bg-white hover:cursor-pointer  hover:scale-105 duration-150 shadow-sm rounded-[8px] py-3 px-3 "
            onClick={() => go("/orders/lotto")}
          >
            <div className="flex gap-2 items-center">
              <BsPostcard className="h-6 w-6" />{" "}
              <p className="font-semibold">สลากของฉัน</p>
            </div>
            <div className="flex mt-2 gap-2 items-center">
              <p className="flex font-semibold text-xl flex-grow text-blue-600">
                {lottoAmount || 0}
              </p>
              <p className="font-semibold">ใบ</p>
            </div>
          </div>
          <div
            className="bg-gray-50 hover:cursor-pointer hover:scale-105 duration-150 shadow-sm rounded-[8px] py-3 px-3 "
            onClick={() => go("/coupon/my_coupon")}
          >
            <div className="flex gap-2 items-center">
              <RiCoupon2Line className="h-6 w-6" />{" "}
              <p className="font-semibold">คูปองของฉัน</p>
            </div>
            <div className="flex mt-2 gap-2 items-center">
              <p className="flex font-semibold text-xl flex-grow text-blue-600">
                {couponAmount || 0}
              </p>
              <p className="font-semibold">ใบ</p>
            </div>
          </div>
          <div className="bg-white hover:cursor-pointer  hover:scale-105 duration-150 shadow-sm rounded-[8px] py-3 px-3 ">
            <div className="flex gap-2 items-center">
              <TbCash className="h-6 w-6" />
              <p className="font-semibold">รายได้ชวนเพื่อน</p>
            </div>
            <div className="flex mt-2 gap-2 items-center">
              <p className="flex font-semibold text-xl flex-grow text-blue-600">
                {profileData.buyCash || 0}
              </p>
              <p className="font-semibold">บาท</p>
            </div>
          </div>
        </div>

        <ContentContainer
          minHeight="0"
          bgcolor="newGrReverse"
          padding="10px 20px"
        >
          <p className="text-white font-bold text-lg">ชวนเพื่อน</p>
        </ContentContainer>
        <ContentContainer margin="10px 0 0 0" minHeight="0" padding="15px 20px">
          <div className="">
            <Typo.Body5 color="gray4" margin="5px 0 0 0">
              ลิ้งก์ชวนเพื่อน
            </Typo.Body5>
            <div className="flex justify-between flex-wrap gap-2">
              <Typo.Body1
                break="break-word"
                id="btnCopyLink"
                margin="3px 0 0 0"
                color="textBlack"
              >
                {configs.isAffiliateOpen ? link[1].value : link[0].value}
              </Typo.Body1>

              <button
                className={`bg-yellow-400 w-full sm:w-fit hover:opacity-85 duration-150 font-semibold px-3 py-1 rounded-[8px] `}
                onClick={() => {
                  const copyText =
                    document.getElementById("btnCopyLink").innerHTML;
                  navigator.clipboard.writeText(copyText);
                  toast({
                    className: "bg-[rgb(59,176,101)]",
                    title: `คัดลอก: ${copyText}`,
                  });
                }}
              >
                คัดลอก
              </button>
            </div>
          </div>
        </ContentContainer>

        {/* //////อัปเดตแจ้งเตือน */}
        <Notify profileData={profileData} save={save} />
        {/* ///////////userinfo */}
        <UserInfo />

        {/* ///////////contact */}
        <Contact />

        {/* ///////////bussines */}
        <Bussines />

        {/* ///////////policy */}
        <Policy />

        <button
          className="w-full mb-10 bg-rose-600 text-white font-bold text-lg py-2 rounded-xl hover:opacity-85 mt-3 duration-150"
          onClick={() => {
            go("/logout");
          }}
        >
          ออกจากระบบ
        </button>
      </Container>
    </Flex>
  );
};

const UserInfo = () => {
  const { go } = Context();
  return (
    <div className="mt-3">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <p className="text-white font-bold text-lg">ข้อมูลสมาชิก</p>
      </ContentContainer>
      <ContentContainer minHeight="0" padding="0">
        <div
          className="flex items-center justify-between p-4 font-semibold text-gray-500 hover:text-gray-950 hover:cursor-pointer duration-150"
          onClick={() => go("/profile/info")}
        >
          <div className="flex items-center gap-3">
            <FaRegUserCircle className="text-2xl w-8 text-left" />
            <p className="">รายละเอียดข้อมูลสมาชิก</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
        <Line />

        <div
          className="flex items-center justify-between p-4 font-semibold text-gray-500 hover:text-gray-950 hover:cursor-pointer duration-150"
          onClick={() => go("/profile/info")}
        >
          <div className="flex items-center gap-3">
            <PiBankBold className="text-2xl w-8 text-left" />
            <p className="">บัญชีธนาคาร</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
        <Line />

        <div
          className="flex items-center justify-between p-4 font-semibold text-gray-500 hover:text-gray-950 hover:cursor-pointer duration-150"
          onClick={() => go("/profile/info")}
        >
          <div className="flex items-center gap-3">
            <TbGps className="text-2xl w-8 -mt-1 text-left" />
            <p className="">ที่อยู่ของฉัน</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
      </ContentContainer>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="mt-3">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <p className="text-white font-bold text-lg">ช่องทางการติดต่อ</p>
      </ContentContainer>

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-2">
        <a href="tel:022466666">
          <div
            className="flex flex-col justify-center gap-1 items-center bg-white rounded-md shadow-md p-4 text-gray-500 hover:text-gray-950 duration-150 cursor-pointer"
            onclick={() => window.open("tel:022466666")}
          >
            <PiPhoneCallFill className="text-2xl" />
            <p className="text-sm font-semibold">Call Center </p>
          </div>
        </a>
        <div
          className="flex flex-col justify-center gap-1 items-center bg-white rounded-md shadow-md p-4 text-gray-500 hover:text-gray-950 duration-150 cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.facebook.com/Hongthonglotterysocial",
              "_blank"
            )
          }
        >
          <FaFacebookSquare className="text-2xl" />
          <p className="text-sm font-semibold">Facebook</p>
        </div>
        <div
          className="flex flex-col justify-center gap-1 items-center bg-white rounded-md shadow-md p-4 text-gray-500 hover:text-gray-950 duration-150 cursor-pointer"
          onClick={() =>
            window.open("https://line.me/R/ti/p/%40995soqsm", "_blank")
          }
        >
          <FaLine className="text-2xl" />
          <p className="text-sm font-semibold">Line</p>
        </div>
        <div
          className="flex flex-col justify-center gap-1 items-center bg-white rounded-md shadow-md p-4 text-gray-500 hover:text-gray-950 duration-150 cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.tiktok.com/@hongthongofficiallottery?_t=ZS-8tbWlNYUWZ8&_r=1}",
              "_blank"
            )
          }
        >
          <FaTiktok className="text-2xl" />
          <p className="text-sm font-semibold">Tiktok</p>
        </div>
      </div>
    </div>
  );
};

const Bussines = () => {
  const { go } = Context();
  return (
    <div className="mt-3">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <p className="text-white font-bold text-lg opacity-65">
          หงษ์ทองสำหรับธุรกิจ (เร็วๆนี้)
        </p>
      </ContentContainer>
      <ContentContainer minHeight="0" padding="0">
        <div
          className="opacity-65 flex items-center justify-between p-4 font-semibold text-gray-500  hover:cursor-pointer duration-150"
          // onClick={() => go("/profile/policy")}
        >
          <div className="flex items-center gap-3 ">
            <PiCards className="text-2xl w-8 text-left" />
            <p className="">ลอตเตอรี่ค้างแผง </p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
        <Line />
        <div
          className="opacity-65 flex items-center justify-between p-4 font-semibold text-gray-500 
          hover:cursor-pointer duration-150"
          // onClick={() => go("/profile/pdpa")}
        >
          <div className="flex items-center gap-3 ">
            <MdOutlineHandshake className="text-2xl w-8 text-left" />

            <p className="">ติดต่อการตลาด</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
      </ContentContainer>
    </div>
  );
};

const Policy = () => {
  const { go } = Context();
  return (
    <div className="mt-3">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <p className="text-white font-bold text-lg">ข้อกำหนดและเงื่อนไข</p>
      </ContentContainer>
      <ContentContainer minHeight="0" padding="0">
        <div
          className="flex items-center justify-between p-4 font-semibold text-gray-500 hover:text-gray-950 hover:cursor-pointer duration-150"
          onClick={() => go("/profile/policy")}
        >
          <div className="flex items-center gap-3 ">
            <IoNewspaperOutline className="text-2xl w-8 text-left" />
            <p className="">ข้อตกลงและเงื่อนไขของแอปฯหงษ์ทอง</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
        <Line />
        <div
          className="flex items-center justify-between p-4 font-semibold text-gray-500 hover:text-gray-950 hover:cursor-pointer duration-150"
          onClick={() => go("/profile/pdpa")}
        >
          <div className="flex items-center gap-3 ">
            <p className="font-bold text-[12px] leading-none w-8 text-center">
              PD
              <br />
              PA
            </p>
            <p className="">จัดการข้อมูลการให้การยินยอม PDPA</p>
          </div>
          <MdOutlineKeyboardArrowRight className="opacity-50" />
        </div>
      </ContentContainer>
    </div>
  );
};

const Notify = ({ profileData, save }) => {
  const [isEdit, setEdit] = useState(false);
  return (
    <div className="mt-3">
      <ContentContainer
        minHeight="0"
        bgcolor="newGrReverse"
        padding="10px 20px"
      >
        <div className="flex justify-between items-center">
          <p className="text-white font-bold text-lg">ช่องทางการแจ้งเตือน</p>

          <button
            className={`
              text-yellow-400 w-fit font-bold text-sm px-2 h-full rounded-[8px] hover:cursor-pointer hover:text-gray-50 duration-150`}
            onClick={() => setEdit(!isEdit)}
          >
            {isEdit ? "ยกเลิก" : "แก้ไข"}
          </button>
        </div>
      </ContentContainer>
      <ContentContainer minHeight="0" padding="15px 20px">
        <Typo.Body5 color="gray4" margin="5px 0 0 0">
          ช่องทาง
        </Typo.Body5>
        {isEdit ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-2">
            <div className="flex gap-1 items-center">
              <input
                type="radio"
                id="takeSms"
                name="messageChannel"
                value="SMS"
                checked={profileData.notifyType === "SMS"}
                onChange={async (e) => {
                  await save([{ key: "notifyType", value: "SMS" }]);
                  setEdit(false);
                }}
                style={{
                  marginRight: "10px",
                  transform: "scale(1.5)",
                  cursor: "pointer",
                }}
              />
              <label
                className="font-semibold"
                htmlFor="takeSms"
                style={{ cursor: "pointer" }}
              >
                SMS ที่เบอร์ {profileData.phone}
              </label>
            </div>

            {profileData.lineId ? (
              <div className="flex gap-1">
                <input
                  type="radio"
                  id="takeLine"
                  name="messageChannel"
                  value="LINE"
                  checked={profileData.notifyType === "LINE"}
                  onChange={async (e) => {
                    await save([{ key: "notifyType", value: "LINE" }]);
                    setEdit(false);
                  }}
                  disabled={!isEdit || !profileData.lineId}
                  style={{
                    marginRight: "10px",
                    transform: "scale(1.5)",
                    cursor: "pointer",
                  }}
                />
                <label
                  className="font-semibold"
                  htmlFor="takeLine"
                  style={{ cursor: "pointer" }}
                >
                  LINE
                </label>
              </div>
            ) : (
              <div
                style={{
                  minWidth: "200px",
                  display: "inline-block",
                  verticalAlign: "middle",
                  padding: "3px",
                }}
              >
                <LineButton
                  text={"เชื่อมบัญชีไลน์"}
                  shouldCenter={false}
                  userId={profileData.userId}
                  appId={profileData.userNo}
                />
              </div>
            )}
          </div>
        ) : (
          <Typo.Body1 margin="3px 0 0 0" color="textBlack">
            แจ้งเตือนผ่านช่องทาง {profileData.notifyType}
          </Typo.Body1>
        )}
      </ContentContainer>
    </div>
  );
};

const Avatar = styled.div`
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 0;

  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border: 4px solid ${themes.colors.white};

  cursor: pointer;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

const Line = styled.div`
  height: 0.5px;
  background-color: black;
  opacity: 0.2;
`;
