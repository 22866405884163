import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as R from "ramda";
import Context from "../Context";
import { devices, fontWeight, isHideFooter } from "../constants";
import themes from "../themes";
import { Flex, Container, Typo, Div } from "./index";
import { useLocation } from "react-router-dom";
import { LogoWrapper } from "./Navbar";

import { HiOutlineHome } from "react-icons/hi";

import { FaSearchDollar } from "react-icons/fa";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { GiWallet } from "react-icons/gi";
import { IoIosListBox } from "react-icons/io";
import { CountDown } from "../util";
import { ImSearch } from "react-icons/im";
import { FaCartPlus } from "react-icons/fa";
import { TbShoppingCart } from "react-icons/tb";

import { LiaMoneyCheckSolid } from "react-icons/lia";

import { FaUserCircle } from "react-icons/fa";

export const Footer = () => {
  const { callApi, setCart, cart, go, profileState, toast } = Context();
  const [ts, setTs] = useState(Number(new Date()));
  const [isCountStart, setIsCountStart] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (cart.size) {
        setTs(Date.now());
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cart.size]);

  const navigateWithValidate = (path) => {
    // if (!profileState.userNo) {
    //   go("/login");
    //   return;
    // }

    go(path);
  };

  const currentPath = location.pathname;
  const parentPath = currentPath.split("/")[1];
  if (isHideFooter(parentPath)) {
    return;
  }

  return (
    <>
      <FooterWrapper
        className={`md:hidden fixed h-28 w-full bottom-0 xs:px-4 flex justify-center items-start`}
      >
        <div className="md:hidden sm:px-3 grid grid-cols-5 pt-3 gap-2 w-full">
          <div
            onClick={() => navigateWithValidate("/")}
            className={`flex text-gray-50 flex-col justify-end gap-2 items-center cursor-pointer duration-150 ${
              parentPath === "" ? "font-bold" : "opacity-70"
            }`}
          >
            <HiOutlineHome className="w-6 h-6 text-white" />
            <p className="text-xxs xs:text-sm sm:text-base">หน้าหลัก</p>
          </div>
          <div
            onClick={() => navigateWithValidate("/check-reward")}
            className={`flex text-gray-50 flex-col justify-end gap-2 items-center cursor-pointer duration-150 ${
              parentPath === "check-reward" ? "font-bold" : "opacity-70"
            } `}
          >
            <ImSearch className={`w-5 h-6 `} />

            <p className="text-xxs xs:text-sm sm:text-base">ตรวจหวย</p>
          </div>
          {cart?.size > 0 && (
            <CartNav
              onClick={() =>
                parentPath === "search"
                  ? navigateWithValidate("/cart")
                  : navigateWithValidate("/search")
              }
              className={parentPath === "search" && "selected"}
            >
              <Div>
                <CartSize className="flex justify-center items-center">
                  <Typo.Body5 textAlign="center">{cart?.size}</Typo.Body5>
                </CartSize>
                <TbShoppingCart className="w-7 h-7" />
              </Div>
              <CountDownWrapper className="flex justify-center items-center">
                <Typo.Body5>
                  <CountDown second={cart?.expireIn} />
                </Typo.Body5>
              </CountDownWrapper>
              <Typo.Body6 weight="semiBold" color="navy">
                ตะกร้า
              </Typo.Body6>
            </CartNav>
          )}

          <div
            onClick={() => navigateWithValidate("/search")}
            className={`flex text-gray-50 flex-col justify-end gap-2 items-center cursor-pointer duration-150 ${
              parentPath === "search" ? "font-bold" : "opacity-70"
            } `}
          >
            <TbShoppingCart className="w-7 h-7" />
            <p className="text-xxs xs:text-sm sm:text-base">ซื้อลอตเตอรี่</p>
          </div>
          {/* </Nav> */}
          <div
            onClick={() => navigateWithValidate("/orders")}
            className={`flex text-gray-50 flex-col justify-end gap-2 items-center cursor-pointer duration-150 ${
              parentPath === "orders" ? "font-bold" : "opacity-70"
            } `}
          >
            <LiaMoneyCheckSolid className="w-7 h-7" />
            <p className="text-xxs xs:text-sm sm:text-base">คำสั่งซื้อ</p>
          </div>

          <div
            onClick={() =>
              navigateWithValidate(profileState.userId ? "/profile" : "/login")
            }
            className={`flex text-gray-50 flex-col justify-end gap-2 items-center cursor-pointer duration-150 ${
              parentPath === "profile" || parentPath === "login"
                ? "font-bold"
                : "opacity-70"
            } `}
          >
            {profileState.profileImage ? (
              <img
                className={`rounded-full w-7 h-7 border-1 ${
                  parentPath === "profile"
                    ? "border-[#FFCD00]"
                    : "border-gray-50"
                }`}
                src={profileState.profileImage}
                alt="profile"
              />
            ) : (
              <FaUserCircle className="w-7 h-7" />
            )}
            <p className="text-xxs xs:text-sm sm:text-base">
              {profileState.userId ? "สมาชิก" : "เข้าสู่ระบบ"}
            </p>
          </div>
        </div>
      </FooterWrapper>
      <DesktopFooter go={navigateWithValidate} />
    </>
  );
};

const DesktopFooter = ({ go }) => {
  return (
    <DesktopWrapper className="hidden md:flex justify-center">
      <Container className="m-0 p-4 ">
        <Flex align="flex-start" justify="space-between" gap="25px">
          <Flex direction="column">
            <LogoWrapper>
              <img
                src="/images/logo.png"
                alt="logo"
                style={{ width: "100%" }}
              />
            </LogoWrapper>
            <Typo.Body4 color="white" margin="25px 0 7px 0">
              บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
            </Typo.Body4>
            <Typo.Body3 color="white80">
              เลขที่ 1094 ถนนสุขุมวิท เเขวงพระโขนง <br />
              เขตคลองเตย กรุงเทพมหานคร 10110
            </Typo.Body3>
          </Flex>
          <Flex direction="column" align="flex-start">
            <Typo.Body4 margin="0 0 7px 0" color="yellow">
              การบริการ
            </Typo.Body4>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/ตรวจรางวัลลอตเตอรี่"
              // onClick={() =>
              //   go("https://www.hongthongofficial.com/ตรวจรางวัลลอตเตอรี่")
              // }
            >
              ตรวจรางวัลลอตเตอรี่
            </Link>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/home/service"
              // onClick={() =>
              //   go("https://www.hongthongofficial.com/home/service")
              // }
            >
              บริการของเรา
            </Link>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/วิธีซื้อลอตเตอรี่ออนไลน์"
              // onClick={() =>
              //   go(
              //     "https://www.hongthongofficial.com/วิธีซื้อลอตเตอรี่ออนไลน์"
              //   )
              // }
            >
              วิธีซื้อลอตเตอรี่ออนไลน์
            </Link>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/home/faq"
              // onClick={() => go("https://www.hongthongofficial.com/home/faq")}
            >
              คำถามที่พบบ่อย
            </Link>

            <Link
              color="white"
              href="https://shop.hongthongofficial.com/complain"
              //  onClick={() => go("/complain")}
            >
              ร้องเรียน
            </Link>
          </Flex>
          <Flex direction="column" align="flex-start">
            <Typo.Body4 margin="0 0 7px 0" color="yellow">
              องค์กร
            </Typo.Body4>

            <Link
              href="https://www.hongthongofficial.com/home/aboutus"
              color="white"
              // onClick={() =>
              //   go("https://www.hongthongofficial.com/home/aboutus")
              // }
            >
              เกี่ยวกับเรา
            </Link>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/news/list/1"
              // onClick={() =>
              //   go("https://www.hongthongofficial.com/news/list/1")
              // }
            >
              ข่าวหงษ์ทองลอตเตอรี่
            </Link>

            <Link
              color="white"
              href="https://www.hongthongofficial.com/วีหงษ์ทอง"
            >
              วี หงษ์ทอง
            </Link>
          </Flex>
          <Flex direction="column">
            <Typo.Body4 margin="0 0 7px 0" color="yellow">
              ช่องทางการติดต่อ
            </Typo.Body4>
            <Typo.H4 margin="0 0 7px 0" color="white">
              02 246 6666
            </Typo.H4>
          </Flex>
        </Flex>
        <VerticalLine />
        <Flex justify="space-between">
          <Div>
            <Typo.Body4 color="white">
              สงวนลิขสิทธิ์ พ.ศ.2564 บริษัท หงษ์ทอง ลอตเตอรี่ออนไลน์ จำกัด
            </Typo.Body4>
            <Typo.Body8 color="white50">version 4.0.4.1</Typo.Body8>
          </Div>

          <Flex gap="20px">
            <Link
              color="white"
              href="https://www.hongthongofficial.com/home/cookie"
            >
              Cookie Policy
            </Link>
            <Link
              color="white"
              href="https://www.hongthongofficial.com/home/PDPA"
            >
              PDPA Policy
            </Link>
            <Link
              color="white"
              href="https://www.hongthongofficial.com/home/policy"
            >
              ข้อตกลงและเงื่อนไข
            </Link>
          </Flex>
        </Flex>
      </Container>
    </DesktopWrapper>
  );
};

const CountDownWrapper = styled.div`
  background-color: ${themes.colors.navy};
  border-radius: 15px;
  padding: 3px 5px;
`;
const CartNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${themes.colors.yellow};
  z-index: 3;
  border-radius: 15px 15px 0 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translatex(-50%);
  height: 110%;
  width: 20%;
`;

const CartSize = styled.div`
  background-color: ${themes.colors.red};
  border-radius: 50%;

  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -5px;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => themes.colors[props.color || "white80"]};
  width: 20%;
  gap: 12px;
  border-top: 8px solid rgb(0, 0, 0, 0);

  cursor: pointer;
  transition-duration: 0.2s;

  &.hide {
    z-index: -1;
  }
  &.selected {
    border-top: 8px solid ${themes.colors.blue};
    color: ${themes.colors.yellow};
  }

  :hover {
    color: ${(props) => themes.colors[props.color || "white"]};

    &.selected {
      color: ${themes.colors.yellow};
    }
  }
`;

const MobileWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 480px) {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 0 20px 2px;
    overflow: visible;
  }
`;

const TabletWrapper = styled.div`
  display: none;
  @media only screen and (${devices.tablet}) {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 480px) {
    display: none;
  }
`;
const DesktopWrapper = styled.div`
  background: ${themes.colors.gradient2};
`;

const FooterWrapper = styled.div`
  background: ${themes.colors.gradient2};
  z-index: 99;
`;

const VerticalLine = styled.div`
  border-bottom: 2px solid ${themes.colors.white50};
  margin: 20px 0;
`;

const Link = styled.a`
  font-size: 16px;
  font-weight: ${fontWeight.regular};
  line-height: 20px;
  margin-bottom: 7px;
  color: ${themes.colors.white};
  cursor: pointer;

  transition-duration: 0.2s;
  :hover {
    color: ${themes.colors.white50};
  }
`;
