import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import style from "../constants/style";
import Context from "../Context";
import { useEffect } from "react";
import { StackImages } from "./index";
import themes from "../themes";
import { Loading } from "../components";
import { FiShoppingBag, FiShoppingCart } from "react-icons/fi";
import { Typo } from "./index";
import Lottie from "lottie-react";
import lottieCart from "../constants/lottie/cart.json";
import lottieTick from "../constants/lottie/tick.json";
import lottieRemove from "../constants/lottie/remove.json";
import { Spinner } from "reactstrap";
import { HiPlusCircle, HiMinusCircle } from "react-icons/hi";

export const ShopItem = ({
  number,
  size,
  lotteries,
  isGuest,
  isMagicNumber,
  isLabubuNumber,
  specialEvent,
}) => {
  const navItemScRef = useRef(null);
  const { callApi, configs, go, toast, cart, setCart, profileState } =
    Context();

  const [status, setStatus] = useState("available");
  const [loterryWidth, setLotteryWidth] = useState(300);
  const [isExpand, setIsExpand] = useState(false);
  const [amount, setAmount] = useState(lotteries.length);
  const [onHover, setOnHover] = useState(false);

  // useEffect(() => {
  //   setLotteryWidth(navItemScRef.current?.clientWidth || 300);
  // }, [navItemScRef.current?.clientWidth]);

  const addCart = async () => {
    const isGuest = !profileState.userNo;
    const guestId = localStorage.guestId || false;
    // if (cart?.size >= 201) {
    //   return toast("จำกัดการซื้อสูงสุดไม่เกิน 200");
    // }

    // if (!localStorage.getItem("accessToken")) {
    //   return go("/login");
    // } else if (isGuest) {
    //   window.location.pathname.includes("/search") && window.location.reload();
    // }
    if (status === "inCart") {
      setStatus("loading");
      const cartResult = await callApi(
        "carts-removeCart",
        { number, guestId },
        { notShowLoading: true }
      );
      const { size, expireIn } = cartResult;
      if (cartResult !== false) {
        setStatus("available");
        setCart((prevState) => ({
          deliverChannel: prevState.deliverChannel || undefined,
          size,
          expireIn: expireIn,
        }));
      }
    } else if (status === "available") {
      setStatus("loading");
      const result = isGuest
        ? await callApi(
            "carts-addGuestCart",
            { number, guestId: guestId, amount },
            { notShowLoading: true }
          )
        : await callApi(
            "carts-addCart",
            { number, amount },
            { notShowLoading: true }
          );

      const { size, expireIn } = result;

      if (!result.status) {
        setStatus("available");
        setIsExpand(false);
        return;
      } else {
        if (result.guestId) {
          localStorage.setItem("guestId", result.guestId);
        }

        setStatus("inCart");
        setIsExpand(false);
        setCart((prevState) => ({
          deliverChannel: prevState?.deliverChannel || undefined,
          size,
          expireIn: expireIn,
        }));
      }
    }
  };
  {
    /* <div
      className={`relative mx-auto w-fit`}
      onClick={() => !isExpand && setIsExpand(true)}
    >
      <div
        className={`absolute flex flex-col justify-center items-center w-full h-full ${
          status === "inCart" && !isExpand ? "bg-[rgb(0,0,0,0.8)]" : "hidden"
        } duration-150`}
      >
        {" "}
        <p className="text-gold text-3xl font-bold">
          {amount} / {lotteries.length}
        </p>
      </div>
      //////// when expand
      <div
        className={`absolute flex flex-col justify-center items-center w-full h-full ${
          isExpand ? "bg-[rgb(0,0,0,0.8)]" : "hidden"
        } duration-150`}
        // onClick={() => isExpand && setIsExpand(false)}
      >
        <div className="flex items-center gap-3">
          <HiMinusCircle
            className={`text-gold text-2xl cursor-pointer active:scale-125 hover:scale-110 ${
              status !== "available" && "hidden"
            }`}
            onClick={() =>
              status === "available" &&
              setAmount((prevState) =>
                prevState > 1 ? prevState - 1 : prevState
              )
            }
          />
          <p className="text-gold text-3xl font-bold">
            {amount} / {lotteries.length}
          </p>

          <HiPlusCircle
            className={`text-gold text-2xl cursor-pointer active:scale-125 hover:scale-110 ${
              status !== "available" && "hidden"
            }`}
            onClick={() =>
              status === "available" &&
              setAmount((prevState) =>
                prevState < lotteries.length ? prevState + 1 : prevState
              )
            }
          />
        </div>
        {status === "available" && (
          <div className="mt-3">
            <button
              className="bg-success text-xs font-semibold w-10/12 rounded-md py-2 hover:opacity-85 duration-150  mb-2 "
              onClick={() => addCart()}
            >
              ใส่ตระกร้า
            </button>
            <button
              className="bg-danger text-xs font-semibold w-10/12 rounded-md py-2 hover:opacity-85 duration-150 "
              onClick={() => setIsExpand(false)}
            >
              ยกเลิก
            </button>
          </div>
        )}

        {status === "inCart" && (
          <button
            className="bg-danger text-xs font-semibold w-10/12 rounded-md py-2 hover:opacity-85 duration-150 mt-2"
            onClick={() => addCart()}
          >
            แก้ไข
          </button>
        )}
      </div>
      {lotteries.map((x, index) => (
        <img
          src={x.image}
          alt={x.image}
          key={x.image}
          className={`${
            index > 0 ? (isExpand ? "-mt-[10%]" : "-mt-[30%]") : "mt-0"
          } duration-150`}
        />
      ))}
    </div> */
  }
  return (
    <>
      <LottoWrapper
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        to={"#"}
        onClick={() => addCart() && setOnHover(false)}
        ref={navItemScRef}
      >
        <LottoContainer>
          <LottoContainer>
            <StackImages
              limitHeight={true}
              status={status}
              isDisable={true}
              urls={lotteries ? lotteries.map((x) => [x.image]) : []}
            ></StackImages>

            {isMagicNumber ? (
              <div className="absolute top-0 left-0 w-14 h-20 sm:w-20 sm:h-32 md:h-24  p-1 flex flex-col justify-center items-center bg-gold border-2 rounded-tl-lg border-navy">
                <img
                  src={configs.magicNumber.image}
                  alt="magicNumber"
                  style={{ width: "100%" }}
                />
                <p className="  text-navy t text-xxs sm:text-base ext-base font-semibold">
                  {lotteries.length} ใบ
                </p>
              </div>
            ) : isLabubuNumber ? (
              <div className="absolute top-0 left-0 w-14 h-20 sm:w-20 sm:h-32 flex flex-col justify-center items-center bg-gold ">
                <img
                  src={configs.labubuNumber.image}
                  alt="magicNumber"
                  style={{ width: "100%" }}
                />
                <p className="  text-navy t text-xs sm:text-base ext-base font-semibold">
                  {lotteries.length} ใบ
                </p>
              </div>
            ) : specialEvent && configs.showButton.specialEvent ? (
              <div className="absolute top-0 left-0 w-14 h-20 sm:w-20 sm:h-32 flex flex-col justify-center items-center bg-gold ">
                <img
                  src={`https://storage.googleapis.com/hongthong-prod-image/magicNumber/S__3768484.png`}
                  alt="magicNumber"
                  style={{ width: "100%" }}
                />
                <p className="  text-navy t text-xs sm:text-base ext-base font-semibold">
                  {lotteries.length} ใบ
                </p>
              </div>
            ) : (
              <div className="absolute top-0 left-0 w-14 h-16 p-1 sm:w-20 sm:h-32 md:w-20 md:h-24 flex flex-col gap-2 justify-center items-center bg-gold border-2 rounded-tl-lg border-navy ">
                <img
                  src="/images/logo_single.png"
                  alt="logo"
                  className="w-6 h-6 sm:w-12 sm:h-12"
                />
                <p className="text-navy text-xxxs sm:text-base font-semibold">
                  {lotteries.length} ใบ {lotteries.length * 6} ล
                </p>
              </div>
            )}
          </LottoContainer>
          <AddButton>
            <FiShoppingCart color="#FFCD00" />

            <Typo.Body8 color="white">{`เพิ่มลงในตะกร้า`}</Typo.Body8>
          </AddButton>
          {status === "inCart" && (
            <CoverStatus>
              <Lottie
                animationData={lottieTick}
                style={{ width: "50px" }}
                loop={false}
              />
              <Typo.Body6 color="white">เพิ่มลงไปในตะกร้าแล้ว</Typo.Body6>
            </CoverStatus>
          )}
          {status === "loading" && (
            <CoverStatus>
              <Spinner className="text-gold w-6 h-6" />
            </CoverStatus>
          )}
          {onHover && !["bought", "inCart", "loading"].includes(status) && (
            <CoverStatus>
              <Lottie animationData={lottieCart} style={{ width: "250px" }} />
            </CoverStatus>
          )}
        </LottoContainer>
      </LottoWrapper>
    </>
  );
};

const P = styled.p`
  margin: 0 0 0 5px;
`;
const LottoWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  color: black;
  background: white;
  border-radius: 15px;
`;

const LottoContainer = styled.div`
  position: relative;
`;

const CoverStatus = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${style.css.smRad}

  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  background-size: cover;
  padding-top: unset;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  left: -5px;
  top: -5px;
  color: white;
  font-weight: bold;
  font-size: 25px;
`;

const TagText = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => (props.isMagicNumber ? "1em 0" : "0")};
  color: ${(props) => props.isMagicNumber && themes.colors.black};
  align-items: center;
  flex-direction: column;
  font-size: ${({ fs }) => fs * 0.06}px;
  line-height: 1;
  font-weight: 600;
  @media only screen and (max-width: 643px) {
    font-size: 2vw;
  }
`;

const AddButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;

  padding: 8px 8px;
  align-items: center;
  background: ${themes.colors.navy};
`;
